<template>
    <svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-scroll-top">
        <path d="M12.7516 6.77082C12.4204 7.08667 11.8838 7.08667 11.5526 6.77082L7.35624 2.76879L7.35624 15.1916C7.35624 15.6382 6.97654 16 6.50853 16L6.49147 16C6.02312 16 5.64376 15.6379 5.64376 15.1916L5.64376 2.76879L1.44736 6.77082C1.11617 7.08667 0.579579 7.08667 0.24839 6.77082C-0.0827979 6.45497 -0.0827979 5.94323 0.24839 5.62738L5.90068 0.236886C6.23187 -0.0789624 6.76846 -0.0789624 7.09965 0.236886L12.7516 5.62738C13.0828 5.94323 13.0828 6.45497 12.7516 6.77082Z" />
    </svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.icon-scroll-top{
    height: 16px;
}
</style>