import axios from "@/plugins/axios";

let url = (data = {}) => "/api/v1/users/contact/${type}".interpolate(data);

export default {
    certify(data) {
        return axios.post(`${url(data)}/certify`, data).then((result) => result.data);
    },
    confirm(data) {
        return axios.post(`${url(data)}/confirm`, data).then((result) => result.data);
    },
};
