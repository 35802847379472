import axios from "@/plugins/axios";

let url = "/api/console/smtp";

export const smtp = {
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
};

export default smtp;
