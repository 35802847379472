var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main",
    scopedSlots: _vm._u([{
      key: "contentsImmersive",
      fn: function () {
        return [_c('main-visual'), _c('main-section', {
          staticClass: "main-section--products main-section--xs main-section--first"
        }, [_c('tit-wrap-dot', {
          attrs: {
            "title": "Products",
            "large": ""
          }
        }), _c('btn-primary', {
          staticClass: "mb-20px mb-md-60px mb-lg-100px",
          attrs: {
            "to": "/product"
          }
        }), _c('main-products')], 1), _c('main-section', {
          staticClass: "main-section--media main-section--xs main-section--last",
          scopedSlots: _vm._u([{
            key: "mainContainerImmersive",
            fn: function () {
              return [_c('div', {
                staticClass: "main-section--media__bg"
              })];
            },
            proxy: true
          }])
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('div', {
          staticClass: "h-100 d-flex flex-column justify-space-between"
        }, [_c('tit-wrap-dot', {
          attrs: {
            "title": "Our Media",
            "large": ""
          }
        }), _c('btn-primary', {
          attrs: {
            "dark": "",
            "to": "/academy"
          }
        })], 1)]), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "7"
          }
        }, [_c('main-media')], 1)], 1)], 1), _c('main-section', {
          staticClass: "main-section--inquiary main-section--sm"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('tit-wrap-dot', {
          attrs: {
            "title": "Inquiry",
            "large": ""
          },
          scopedSlots: _vm._u([{
            key: "txtAdd",
            fn: function () {
              return [_vm._v(" 상담 예약을 남겨주시면 친절히 상담해 드리겠습니다. ")];
            },
            proxy: true
          }])
        })], 1), _c('v-col', {
          staticClass: "mt-n16px mt-md-0",
          attrs: {
            "cols": "12",
            "md": "7"
          }
        }, [_c('div', {
          staticClass: "main-section--inquiary__right"
        }, [_c('form-input', {
          attrs: {
            "code": "inquiry-call",
            "skin": "main-inquiry"
          }
        })], 1)])], 1)], 1), _vm._l(_vm.popups, function (popup) {
          return _c('popup-layer', {
            key: popup._id,
            attrs: {
              "popup": popup
            }
          });
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }