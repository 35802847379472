export const USAGE_TARGET_TYPES = {
    ALLITEMS: { value: "ALLITEMS", text: "전체" },
    PRODUCTS: { value: "PRODUCTS", text: "제품" },
    EXHIBITS: { value: "EXHIBITS", text: "기획전" },
    DISPLAYS: { value: "DISPLAYS", text: "제품전시" },
    CATEGORY: { value: "CATEGORY", text: "카테고리" },
};

export const USAGE_TARGET_PRICES = {
    TOTAL_ORDER_AMOUNT: { value: "TOTAL_ORDER_AMOUNT", text: "전체주문금액" },
    SHOP_PRODUCT_PRICE: { value: "SHOP_PRODUCT_PRICE", text: "제품가격" },
    SHOP_DELIVERY_FARE: { value: "SHOP_DELIVERY_FARE", text: "배송비" },
    INSTALLATION_PRICE: { value: "INSTALLATION_PRICE", text: "설치비" },
    ONSITE_SERVICE_FEE: { value: "ONSITE_SERVICE_FEE", text: "출장케어" },
};
