<template>
    <svg  viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-shopping-bag">
        <g clip-path="url(#clip0_2086_31683)">
            <path d="M7.92188 17.3828C7.92188 18.4005 8.74984 19.2285 9.76755 19.2285C10.7853 19.2285 11.6132 18.4005 11.6132 17.3828C11.6132 16.3651 10.7853 15.5371 9.76755 15.5371C8.74984 15.5371 7.92188 16.3651 7.92188 17.3828ZM9.76755 16.7676C10.1068 16.7676 10.3828 17.0435 10.3828 17.3828C10.3828 17.722 10.1068 17.998 9.76755 17.998C9.42831 17.998 9.15232 17.722 9.15232 17.3828C9.15232 17.0435 9.42831 16.7676 9.76755 16.7676Z"/>
            <path d="M14.1172 17.3828C14.1172 18.4005 14.9452 19.2285 15.9629 19.2285C16.9806 19.2285 17.8085 18.4005 17.8085 17.3828C17.8085 16.3651 16.9806 15.5371 15.9629 15.5371C14.9452 15.5371 14.1172 16.3651 14.1172 17.3828ZM15.9629 16.7676C16.3021 16.7676 16.5781 17.0435 16.5781 17.3828C16.5781 17.722 16.3021 17.998 15.9629 17.998C15.6236 17.998 15.3476 17.722 15.3476 17.3828C15.3476 17.0435 15.6236 16.7676 15.9629 16.7676Z"/>
            <path d="M20.8844 3.23238H5.31175C5.1011 2.49498 4.9048 1.80789 4.73619 1.21773C4.66076 0.953591 4.41931 0.771484 4.14463 0.771484H1.11522C0.775457 0.771484 0.5 1.04694 0.5 1.38671C0.5 1.72648 0.775457 2.00193 1.11522 2.00193H3.68055C4.36853 4.41017 5.97689 10.0396 6.65118 12.3996L6.41809 12.8658C5.80389 14.0943 6.69839 15.5369 8.06895 15.5369H18.4235C18.7633 15.5369 19.0388 15.2614 19.0388 14.9217C19.0388 14.5819 18.7633 14.3064 18.4235 14.3064H8.06895C7.61138 14.3064 7.31423 13.825 7.51865 13.4161L7.6887 13.076H18.4235C18.6982 13.076 18.9396 12.8939 19.0151 12.6298L21.476 4.01663C21.5881 3.62432 21.2934 3.23238 20.8844 3.23238ZM17.9595 11.8455H7.77257C7.43736 10.6722 6.49918 7.38864 5.66329 4.46283H20.0689L17.9595 11.8455Z"/>
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-shopping-bag {
    width: 24px;
}
</style>
