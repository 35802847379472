<template>
    <terms-dialog v-model="selected" v-bind="{ params }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template v-slot:activator="{ on, isValid }">
            <div v-on="on">
                <div class="pb-12px">
                    <v-checkbox v-bind="{ ...checkbox_primary, ...$attrs }" readonly label="약관에 전체 동의합니다." :value="isValid" class="v-size--large" />
                </div>
                <v-card outlined v-if="!hideItems">
                    <div class="pa-16px pa-md-20px">
                        <v-checkbox v-for="(terms, index) in termsList" :key="terms._id" :value="selected.includes(terms._id)" v-bind="{ ...checkbox_primary, ...$attrs }" readonly :class="{ 'mt-8px mt-md-12px': index != 0 }">
                            <template #label>
                                {{ terms.subject }}에 동의
                                <span class="pl-4px" :class="[terms.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "필수" : "선택" }})</span>
                            </template>
                        </v-checkbox>

                        <!-- <v-row v-for="terms in termsList" :key="terms._id" align="center" class="row--xs">
                            <v-col cols="">
                                <v-checkbox :value="selected.includes(terms._id)" hide-details color="primary">
                                    <template #label>
                                        {{ terms.subject }}에 동의
                                        <span class="pl-4px" :class="[terms.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "필수" : "선택" }})</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn v-on="on" small text color="grey lighten-1" class="v-btn--text-underline"> 내용보기</v-btn>
                            </v-col>
                        </v-row> -->
                    </div>
                </v-card>
            </div>
        </template>
    </terms-dialog>
</template>

<script>
import { checkbox_primary } from "@/assets/variables";

import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
        hideItems: { type: Boolean, default: false },
    },
    data: () => ({
        checkbox_primary,

        termsList: [],
        selected: [],
    }),
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style></style>
