export const LEGACY_ORDER_PAYMENT_METHODS = {
    PB: { value: "PB", text: "선수금결제" },
    PC: { value: "PC", text: "신용카드" },
    PN: { value: "PN", text: "계좌이체" },
    PV: { value: "PV", text: "가상계좌" },
    PH: { value: "PH", text: "휴대폰" },
    KK: { value: "KK", text: "카카오페이" },
};

export const LEGACY_ORDER_STATUSES = {
    OR: { value: "OR", text: "주문접수" },
    OY: { value: "OY", text: "결제완료" },
    DR: { value: "DR", text: "배송준비중" },
    DI: { value: "DI", text: "배송처리" },
    DC: { value: "DC", text: "배송완료" },
    OC: { value: "OC", text: "주문취소" },
    RD: { value: "RD", text: "취소요청" },
    RC: { value: "RC", text: "취소완료" },
    CD: { value: "CD", text: "교환요청" },
    CC: { value: "CC", text: "교환완료" },
};
