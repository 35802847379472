import CryptoJS from "crypto-js";

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY;

export const encrypt = (planText) => {
    return CryptoJS.AES.encrypt(planText, CRYPTO_SECRET_KEY).toString();
};
export const decrypt = (cyperText) => {
    return CryptoJS.AES.decrypt(cyperText, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8);
};

export default {
    encrypt,
    decrypt,
};
