import axios from "@/plugins/axios";

let url = "/api/v1/shop/catalogues";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    putViews(data) {
        return axios.put(`${url}/${data._id}/views`).then((result) => result.data);
    },
};
