<template>
    <div v-if="className == 'sub-tab'" :class="className">
        <template v-for="item in gnbs">
            <v-tabs v-if="item.title == sh" :key="item.title" hide-slider>
                <v-tab v-for="child in item.children" :key="child.title" :to="child.path" :class="{ 'v-tab--active': tabActive == child.title }">{{ child.title }}</v-tab>
            </v-tabs>
        </template>
    </div>
    <v-row v-else-if="className == 'gnb'" :class="className" class="row--lg">
        <template v-for="item in gnbs">
            <v-col :key="item.title" cols="auto">
                <v-btn :to="item.path" text x-large color="grey darken-4" class="font-weight-bold">
                    {{ item.title }}
                </v-btn>
            </v-col>
        </template>
    </v-row>
    <ul v-else-if="className == 'gnb-mo'" :class="className">
        <template v-for="item in gnbs">
            <li :key="item.title" :class="className + '__list'">
                <router-link :to="item.path" :class="className + '__list-link'">{{ item.title }}</router-link>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        className: String,
    },
    data: () => ({
        window: {
            width: 0,
            height: 0,
        },
        gnbs: [
            // { title: "Product", path: "/product" },
            { title: "bright Academy", path: "/academy" },
            { title: "Library", path: "/library/software" },
            // { title: "Point Mall", path: "/" },
            { title: "Mall", path: "/shop" },
        ],
    }),
    computed: {
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created: function () {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-btn{
        &::before{
            display: none;
        }
    }
    .v-btn.v-size--x-large:not(.v-btn--icon) {
        min-width: 0;
        padding: 0 10px;
        height: 50px !important;
    }
}
@media (min-width:1024px){
    .v-application .v-btn.grey--text.text--darken-4:hover {
        background-color: var(--v-primary-base) !important;
        color: #fff !important;
    }
}
</style>
