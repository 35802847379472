import Vue from "vue";

import { PURCHASE_PURCHASE_STATUSES, PURCHASE_CLAIM_STATUSES } from "@/assets/variables/";
import { SHIPPING_OPTION_BUNDLE_TYPES, SHIPPING_OPTION_CHARGE_TYPES, SHOP_COUPON_UNITS, USAGE_TARGET_PRICES } from "../assets/variables/constants";

import { getDistanceBetweenLocations } from "./vue-methods-geolocation";

export const getCartProductGroups = function (carts = []) {
    return carts.reduce((groups, cart) => {
        const { product } = cart;

        const group = groups.find(({ product: { _id } }) => _id == product._id);
        if (group) group.carts.push(cart);
        else {
            groups.push({
                _product: cart?._product,

                _shipping: cart?._shipping,
                _shippingOption: cart?._shippingOption,

                product,
                shippingOption: cart?.shippingOption?.toObject?.() || cart?.shippingOption,

                carts: [cart],

                shippingCode: cart.shippingCode,
                shippingBundleType: cart.shippingBundleType,
            });
        }

        return groups;
    }, []);
};

export const getCartShippingGroups = function (carts = []) {
    return getCartProductGroups(carts).reduce((shippingGroups, productGroup) => {
        const { shippingCode, shippingBundleType } = productGroup;

        const group = shippingGroups.find((group) => group.shippingCode == shippingCode);
        const needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;
        if (needsBundling && group) {
            group.productGroups.push(productGroup);
        } else {
            shippingGroups.push({
                shippingCode,
                shippingBundleType,

                productGroups: [productGroup],
            });
        }

        return shippingGroups;
    }, []);
};

export const getProductPrice = function (carts = []) {
    return carts.reduce((sum, { salePrice, discountPrice, amount }) => sum + (salePrice + discountPrice) * amount, 0);
};

export const getServicePrice = function (carts = []) {
    return carts.reduce((sum, { shippingOption, amount }) => {
        if (!shippingOption?.service?.isActive) return sum;
        else return sum + (shippingOption?.service?.charge?.amount || 0) * amount;
    }, 0);
};

export const getDiscountPrice = function (carts = []) {
    return carts.reduce((sum, { discountPrice, amount }) => sum + discountPrice * amount, 0);
};

export const getDeliveryPrice = function (carts = [], receiver, setting) {
    if (receiver?.isFactory) return 0;
    return getCartShippingGroups(carts).reduce((totalDeliveryPrice, shippingGroup) => {
        const { shippingBundleType, productGroups } = shippingGroup;
        const needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;

        const { warehouse: { geolocation: warehouseLocation } = {} } = (setting?.shop?.shippingCodes || []).find(({ value }) => value == shippingGroup.shippingCode) || {};

        let travelDistance = 0;
        if (receiver?.geolocation?.coordinates?.[0] && warehouseLocation?.coordinates?.[0]) {
            travelDistance = getDistanceBetweenLocations(receiver?.geolocation, warehouseLocation);
        }

        const productPrice = getProductPrice(productGroups.flatMap(({ carts }) => carts));

        const shippingGroupDeliveryPrice = productGroups.reduce((shippingGroupDeliveryPrice, productGroup) => {
            const { shippingOption } = productGroup;

            let productGroupDeliveryPrice;
            switch (shippingOption?.charge?.type) {
                ////////////////////////////////////////////////////////
                // 착불
                ////////////////////////////////////////////////////////
                case SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value: {
                    productGroupDeliveryPrice = 0;
                    break;
                }

                ////////////////////////////////////////////////////////
                // 고정
                ////////////////////////////////////////////////////////
                case SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value: {
                    productGroupDeliveryPrice = shippingOption.charge.fixedAmount;
                    break;
                }

                ////////////////////////////////////////////////////////
                // 구간별
                ////////////////////////////////////////////////////////
                case SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value: {
                    productGroupDeliveryPrice = [...(shippingOption.charge.pricesRange || [])].sort((a, b) => b.price - a.price).find((item) => item.price <= productPrice)?.amount || 0;
                    break;
                }

                ////////////////////////////////////////////////////////
                // 거리별
                ////////////////////////////////////////////////////////
                case SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value: {
                    productGroupDeliveryPrice = [...(shippingOption.charge.travelRange || [])].sort((a, b) => b.distance - a.distance).find((item) => item.distance <= travelDistance)?.amount || 0;
                    break;
                }

                ////////////////////////////////////////////////////////
                // 기본값
                ////////////////////////////////////////////////////////
                default: {
                    productGroupDeliveryPrice = 0;
                    break;
                }
            }

            ////////////////////////////////////////////////////////////
            // 묶음배송
            ////////////////////////////////////////////////////////////
            if (needsBundling) {
                if (shippingGroupDeliveryPrice == undefined) return productGroupDeliveryPrice;

                const isPreviousLarger = shippingGroupDeliveryPrice > productGroupDeliveryPrice;
                if (isPreviousLarger) return productGroupDeliveryPrice;
                else return shippingGroupDeliveryPrice;
            }
            ////////////////////////////////////////////////////////////
            // 개별배송
            ////////////////////////////////////////////////////////////
            else {
                return (shippingGroupDeliveryPrice || 0) + productGroupDeliveryPrice;
            }
        }, undefined);

        return totalDeliveryPrice + shippingGroupDeliveryPrice;
    }, 0);
};

export const getCouponPrice = function (carts = [], coupons = [], receiver, setting) {
    ////////////////////////////////////////////////////////////////////////////
    // 쿠폰순서 정렬
    ////////////////////////////////////////////////////////////////////////////
    coupons = coupons.reduce((o, coupon) => ({ ...o, [coupon?.usage?.target?.price]: [...(o[coupon?.usage?.target?.price] || [])].concat(coupon) }), {});

    coupons = [...(coupons[USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value] || []), ...(coupons[USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value] || []), ...(coupons[USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value] || [])].map((item) => item);

    return coupons.reduce(
        ({ totalCouponPrice, targetPriceMap, couponPriceMap }, coupon) => {
            ////////////////////////////////////////////////////////////////////
            // 쿠폰대상가격 계산
            ////////////////////////////////////////////////////////////////////
            let targetPrice = undefined;
            let targetPriceKey = undefined;
            switch (coupon.usage.target.price) {
                // 제품가격 쿠폰
                case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                    targetPriceKey = `${coupon._product}`;
                    targetPrice = targetPriceMap.get(targetPriceKey);
                    if (targetPrice === undefined) {
                        targetPrice = carts.reduce((targetPrice, cart) => (`${cart._product}` == `${coupon._product}` ? targetPrice + cart.salePrice * cart.amount : targetPrice), 0);
                    }
                    break;
                }

                // 배송비 쿠폰
                case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                    targetPriceKey = coupon.shippingCode;
                    targetPrice = targetPriceMap.get(targetPriceKey);

                    if (targetPrice === undefined) {
                        targetPrice = getDeliveryPrice(
                            carts.filter(({ shippingCode }) => shippingCode == coupon.shippingCode),
                            receiver,
                            setting
                        );
                    }
                    break;
                }

                // 전체주문금액 쿠폰
                case USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value: {
                    targetPriceKey = USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value;
                    targetPrice = targetPriceMap.get(targetPriceKey);
                    if (targetPrice === undefined) {
                        targetPrice = carts.reduce((targetPrice, cart) => targetPrice + cart.salePrice * cart.amount, 0);

                        if (coupon.usage.combinable) targetPrice -= couponPriceMap.get(USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value) || 0;
                    }
                    break;
                }
            }

            ////////////////////////////////////////////////////////////////////
            // 쿠폰적용가격 계산
            ////////////////////////////////////////////////////////////////////
            let couponPrice = 0;
            switch (coupon.unit) {
                case SHOP_COUPON_UNITS.AMOUNT.value: {
                    couponPrice = coupon.value;
                    break;
                }
                case SHOP_COUPON_UNITS.PERCENT.value: {
                    couponPrice = Math.ceil((targetPrice * coupon.value) / 100);
                    if (!!coupon.limit && coupon.limit < couponPrice) couponPrice = coupon.limit;
                    break;
                }
            }
            if (targetPrice < couponPrice) couponPrice = targetPrice;

            ////////////////////////////////////////////////////////////////////
            // 결과 데이터바인딩
            ////////////////////////////////////////////////////////////////////
            totalCouponPrice += couponPrice;

            targetPriceMap.set(targetPriceKey, targetPrice - couponPrice);

            const couponPriceKey = coupon.usage.target.price;
            couponPrice += couponPriceMap.get(couponPriceKey) || 0;
            couponPriceMap.set(couponPriceKey, couponPrice);

            return {
                totalCouponPrice,
                targetPriceMap,
                couponPriceMap,
            };
        },
        {
            totalCouponPrice: 0,
            targetPriceMap: new Map(),
            couponPriceMap: new Map(),
        }
    ).totalCouponPrice;
};

export const getIslandPrice = function (carts = [], islands = [], postcode) {
    if (!postcode) return 0;

    const island = islands.find((island) => island.areas.find((area) => area.postcode == postcode));
    if (!island) return 0;

    return getCartShippingGroups(carts).reduce((totalIslandPrice, shippingGroup) => {
        const { shippingBundleType, productGroups } = shippingGroup;
        const needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;

        const shippingGroupIslandPrice = productGroups.reduce((shippingGroupIslandPrice, productGroup) => {
            const { shippingOption } = productGroup;

            let productGroupIslandPrice = 0;
            if (shippingOption.island.isActive) {
                switch (island.code) {
                    case "normal": {
                        productGroupIslandPrice = shippingOption.island.charge.amount__base;
                        break;
                    }
                    case "jeju": {
                        productGroupIslandPrice = shippingOption.island.charge.amount__jeju;
                        break;
                    }
                }
            }

            ////////////////////////////////////////////////////////////
            // 묶음배송
            ////////////////////////////////////////////////////////////
            if (needsBundling) {
                const isPreviousLarger = shippingGroupIslandPrice > productGroupIslandPrice;
                if (isPreviousLarger) return shippingGroupIslandPrice;
                else return productGroupIslandPrice;
            }
            ////////////////////////////////////////////////////////////
            // 개별배송
            ////////////////////////////////////////////////////////////
            else {
                return shippingGroupIslandPrice + productGroupIslandPrice;
            }
        }, 0);

        return totalIslandPrice + shippingGroupIslandPrice;
    }, 0);
};

export const getIsShippingAvailable = function (carts = [], receiver, setting) {
    return !getCartShippingGroups(carts).some((shippingGroup) => {
        if (!receiver.address1) return false;

        const { areas__available = [] } = (setting?.shop?.shippingCodes || []).find(({ value }) => value == shippingGroup.shippingCode) || {};

        if (!areas__available.length) return false;

        return !areas__available.some((sido) => receiver?.address1?.indexOf?.(sido) == 0);
    });
};

export const getStatusText = function ({ purchaseStatus, claimStatus, orderStatusMessage, claimStatusMessage } = {}) {
    if (purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value) {
        return "구매확정";
    }

    switch (claimStatus) {
        case PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value: {
            return claimStatusMessage;
        }
        case PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value: {
            return (() => {
                let text = "";
                if (orderStatusMessage) text += orderStatusMessage;
                if (claimStatusMessage) text += text ? ` / ${claimStatusMessage}` : claimStatusMessage;
                return text;
            })();
        }
    }
    return orderStatusMessage;
};

export const decode__productOptionName = (name = "") => [...name.split(" / ")].map((item) => item.split(": ").map(decodeURIComponent).join(": ")).join(" / ");

const ShopMethods = {
    install: function (Vue) {
        Vue.prototype.$getCartProductGroups = getCartProductGroups;

        Vue.prototype.$getCartShippingGroups = getCartShippingGroups;

        Vue.prototype.$getProductPrice = getProductPrice;

        Vue.prototype.$getServicePrice = getServicePrice;

        Vue.prototype.$getDiscountPrice = getDiscountPrice;

        Vue.prototype.$getCouponPrice = getCouponPrice;

        Vue.prototype.$getDeliveryPrice = getDeliveryPrice;

        Vue.prototype.$getIslandPrice = getIslandPrice;

        Vue.prototype.$getIsShippingAvailable = getIsShippingAvailable;

        Vue.prototype.$getStatusText = getStatusText;

        Vue.prototype.$decode__productOptionName = decode__productOptionName;
    },
};

Vue.use(ShopMethods);
