<template>
    <v-btn v-bind="$attrs" text color="grey darken-4" class="v-btn--arrow" :class="'v-size--'+size+' v-btn--arrow--'+pointcolor" @click="$emit('click')">
        <span v-if="!this.$slots['default']">More view</span>
        <slot v-else />
        <icon-arrow-long direction="right" :class="size == 'xx-large' ? 'ml-10px ml-md-32px' : 'ml-10px ml-md-16px'" />
    </v-btn>
</template>

<script>
import IconArrowLong from "@/components/client/icons/icon-arrow-long.vue";
export default {
    props: {
        size: { type: String, default: "x-large" },
        pointcolor: { type: String, default: "primary" },
    },
    components: {
        IconArrowLong,
    },
}
</script>

<style lang="scss" scoped>
.v-btn--arrow{
    --btn-height-xxl : 44px;
    width: fit-content;
    &.v-btn{
        ::v-deep{
            .v-btn__content{
                position: relative;
                &::before{
                    content: "";
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    background-color: rgba(154, 159, 162, .2);
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 0;
                    transition: 0.25s ease-out;
                }
                .icon-arrow-long{
                    position: relative;
                    z-index: 1;
                }
            }
        }
        &.v-size--x-large{
            font-size: var(--btn-text-font-size-lg) !important;
            padding: 0 !important;
            ::v-deep{
                .v-btn__content{
                    padding-right: calc(var(--btn-height-xl) / 2);
                    &::before{
                        width: var(--btn-height-xl);
                        height: var(--btn-height-xl);
                    }
                }
            }
        }
        &.v-size--xx-large{
            padding: 0 !important;
            ::v-deep{
                .v-btn__content{
                    height: 100%;
                    padding-right: calc(var(--btn-height-xxl) / 2);
                    &::before{
                        width: var(--btn-height-xxl);
                        height: var(--btn-height-xxl);
                    }
                }
            }
        }
        &::before{
            display: none;
        }
        &.theme--dark{
            ::v-deep{
                .v-btn__content{
                    color: #fff;
                    &::before{
                        background-color: rgba(255, 255, 255, .2);
                    }
                }
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-btn--arrow{
        --btn-height-xxl : 60px;
    }
}
@media (min-width:1024px){
    .v-btn--arrow{
        &.v-btn{
            &:hover{
                ::v-deep{
                    .v-btn__content{
                        &::before{
                            background-color: var(--v-primary-lighten5);
                        }
                    }
                }
            }              
        }
        &--red{
            &.v-btn{
                &:hover{
                    ::v-deep{
                        .v-btn__content{
                            &::before{
                                background-color: #F8C1C0;
                            }
                        }
                    }
                }              
            }
        }
    }
    .btn-card:hover{
        .v-btn--arrow{
            &.v-btn{
                ::v-deep{
                    .v-btn__content{
                        &::before{
                            background-color: var(--v-primary-lighten5);
                        }
                    }
                }          
            }
        }
    }
    .btn-card.btn-card--red:hover{
        .v-btn--arrow{
            &.v-btn{
                ::v-deep{
                    .v-btn__content{
                        &::before{
                            background-color: #F8C1C0;
                        }
                    }
                }          
            }
        }
    }
}
@media (min-width:1200px){
}

</style>