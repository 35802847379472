export const CHAT_ROOM_TYPES = {
    AI_PROMPT: { value: "AI_PROMPT", text: "AI 프롬프트 채팅" },
    SITE_HELP: { value: "SITE_HELP", text: "실시간 상담" },
};

export const SITE_HELP_CHAT_ROOM_STAGES = {
    BEFORE_AGREEMENT: { value: "BEFORE_AGREEMENT", text: "약관동의" },
    AWAITING_MANAGER: { value: "AWAITING_MANAGER", text: "상담대기" },
    COUNSEL_PROGRESS: { value: "COUNSEL_PROGRESS", text: "상담진행" },
    COUNSEL_COMPLETE: { value: "COUNSEL_COMPLETE", text: "상담종료" },
};
