<template>
    <div class="tit-wrap--xxl">
        <div class="tit--dot" :class="{ 'align-center': alignCenter, 'tit--dot--lg': large }">
            <slot v-if="this.$slots['default']" />
            <h2 v-else :class="large ? 'tit tit--xl font-size-lg-88' : 'tit tit--lg'">{{title}}</h2>      
            <p v-if="this.$slots['txtAdd']" :class="large ? 'txt txt--lg txt--dark mt-20px mt-md-40px' : 'txt txt--sm line-height-15 mt-10px mt-md-20px'">
                <slot name="txtAdd" />
            </p>          
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },        
        large: { type: Boolean, default: false },
        alignCenter: { type: Boolean, default: false },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit--dot{
    .tit{
        width: fit-content;
        padding-right: 22px;
        position: relative;
        line-height: 1;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--v-grey-lighten1);
            top: 0;
            right: 0;
        }
    }
    &.align-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    &--lg{
        .tit{
            &::after{
                background-color: var(--v-primary-base);
            }
        }
    }
}
.white--text{
    .tit--dot{
        .tit{
            &::after{
                background-color: #fff;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit--dot{
        &--lg{
            .tit{
                padding-right: 26px;
                &::after{
                    width: 16px;
                    height: 16px;
                    background-color: var(--v-primary-base);
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
