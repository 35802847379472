var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.slide, function (detail) {
    return [_c('swiper-slide', {
      key: detail
    }, [_c('v-responsive', {
      staticClass: "main-visual__inner",
      style: 'background-image: url(' + detail + ')',
      attrs: {
        "aspect-ratio": 1920 / 980
      }
    })], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }