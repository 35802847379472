var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap--xxl"
  }, [_c('div', {
    staticClass: "tit--dot",
    class: {
      'align-center': _vm.alignCenter,
      'tit--dot--lg': _vm.large
    }
  }, [this.$slots['default'] ? _vm._t("default") : _c('h2', {
    class: _vm.large ? 'tit tit--xl font-size-lg-88' : 'tit tit--lg'
  }, [_vm._v(_vm._s(_vm.title))]), this.$slots['txtAdd'] ? _c('p', {
    class: _vm.large ? 'txt txt--lg txt--dark mt-20px mt-md-40px' : 'txt txt--sm line-height-15 mt-10px mt-md-20px'
  }, [_vm._t("txtAdd")], 2) : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }