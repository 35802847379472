import faqs from "./faqs";
import notifications from "./notifications";
import prompts from "./prompts";
import questions from "./questions";

export default {
    faqs,
    notifications,
    prompts,
    questions,
};
