import bandBanners from "./band-banners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import chats from "./chats";
import files from "./files";
import forms from "./forms";
import inquiries from "./inquiries";
import legacy from "./legacy";
import levels from "./levels";
import popups from "./popups";
import setting from "./setting";
import settlements from "./settlements";
import shop from "./shop";
import smtp from "./smtp";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";

export default {
    bandBanners,
    banners,
    boards,
    center,
    chats,
    files,
    forms,
    inquiries,
    legacy,
    levels,
    popups,
    setting,
    settlements,
    shop,
    smtp,
    statistics,
    terms,
    users,
};
