export const routes = [
    {
        // 실시간상담 메인
        path: "/counsel",
        component: () => import(/* webpackChunkName: "counsel-app" */ "@/pages/counsel/Main.vue"),
        props: true,
    },
    {
        // 실시간상담 목록
        path: "/counsel/site-help-chats",
        component: () => import(/* webpackChunkName: "counsel-app" */ "@/pages/counsel/chats/SiteHelpChats.vue"),
        props: true,
    },
];
