<template>
    <v-card flat tile v-bind="{ loading }">
        <v-divider v-if="!hideTitle" style="border-width: 2px 0 0" class="grey darken-4" />
        <v-row no-gutters class="ma-0">
            <v-col v-for="(item, index) in items" :key="index" cols="12" :lg="item?.cols ?? defaultCols" class="d-flex flex-column">
                <template v-if="isArray(item)">
                    <vertical-table-row v-for="child in item" :key="child.term" v-bind="child" :hideTitle="hideTitle" :noDevider="hideTitle">
                        <template v-if="child.data"> {{ child.data }} </template>
                        <slot v-else-if="$slots[child.term]" :name="child.term" />
                        <v-node v-else-if="child.node" :node="child.node" />
                    </vertical-table-row>
                </template>
                <template v-else>
                    <vertical-table-row v-bind="item" :hideTitle="hideTitle" :noDevider="hideTitle">
                        <template v-if="item.data"> {{ item.data }} </template>
                        <slot v-else-if="$slots[item.term]" :name="item.term" />
                        <v-node v-else-if="item.node" :node="item.node" />
                    </vertical-table-row>
                </template>
            </v-col>
        </v-row>
        <slot name="foot" />
    </v-card>
</template>

<script>
import VerticalTableRow from "./vertical-table-row.vue";
export default {
    components: {
        vNode: { functional: true, render: (h, ctx) => ctx.props.node },
        VerticalTableRow,
    },
    props: {
        items: { type: Array, default: () => [] },
        itemsPerRow: { type: [Number, String], default: 1 },

        loading: { type: Boolean, default: false },
        hideTitle: { type: Boolean, default: false },
    },
    computed: {
        defaultCols() {
            return 12 / this.itemsPerRow;
        },
    },
    methods: {
        isArray(item) {
            return Array.isArray(item);
        },
    },
};
</script>

<style lang="scss" scoped></style>
