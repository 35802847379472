<template>
    <section class="main-section">
        <slot v-if="this.$slots['mainContainerImmersive']" name="mainContainerImmersive" />
        <!-- <div v-else class="container"> -->
        <template>
            <v-container class="container--lg">
                <slot />
            </v-container>
        </template>
    </section>
</template>

<script>
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";

export default {
    props: {
    },
    components: {
        TitWrapSubtitle,
    },
};
</script>

<style lang="scss" scoped>
// main Section
.main-section {
    overflow: hidden;
    position: relative;
    padding: var(--main-section-padding-y) 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &--sm {
        padding: var(--main-section-padding-y-sm) 0;
    }
    &--xs {
        padding: var(--main-section-padding-y-xs) 0;
        &.main-section {
            &--first {
                padding-top: var(--main-section-padding-y);
            }
            &--last {
                padding-bottom: var(--main-section-padding-y);
            }
        }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
</style>
