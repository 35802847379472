var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-youtube",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.73341 15.4286L15.6154 12L9.73341 8.57143V15.4286ZM22.8347 6.48C22.9821 7.01714 23.0841 7.73714 23.1521 8.65143C23.2314 9.56571 23.2654 10.3543 23.2654 11.04L23.3334 12C23.3334 14.5029 23.1521 16.3429 22.8347 17.52C22.5514 18.5486 21.8941 19.2114 20.8741 19.4971C20.3414 19.6457 19.3667 19.7486 17.8707 19.8171C16.3974 19.8971 15.0487 19.9314 13.8021 19.9314L12.0001 20C7.25141 20 4.29341 19.8171 3.12608 19.4971C2.10608 19.2114 1.44875 18.5486 1.16541 17.52C1.01808 16.9829 0.916081 16.2629 0.848081 15.3486C0.768748 14.4343 0.734748 13.6457 0.734748 12.96L0.666748 12C0.666748 9.49714 0.848081 7.65714 1.16541 6.48C1.44875 5.45143 2.10608 4.78857 3.12608 4.50286C3.65875 4.35429 4.63341 4.25143 6.12941 4.18286C7.60275 4.10286 8.95141 4.06857 10.1981 4.06857L12.0001 4C16.7487 4 19.7067 4.18286 20.8741 4.50286C21.8941 4.78857 22.5514 5.45143 22.8347 6.48Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }