import axios from "@/plugins/axios";

import contact from "./contact";

let url = "/api/v1/users";

export default {
    contact,

    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    certify(data) {
        return axios.post(`${url}/certify`, data).then((result) => result.data);
    },
    recovery(data) {
        return axios.post(`${url}/recovery`, data).then((result) => result.data);
    },
};
