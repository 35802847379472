var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab' ? _c('div', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [item.title == _vm.sh ? _c('v-tabs', {
      key: item.title,
      attrs: {
        "hide-slider": ""
      }
    }, _vm._l(item.children, function (child) {
      return _c('v-tab', {
        key: child.title,
        class: {
          'v-tab--active': _vm.tabActive == child.title
        },
        attrs: {
          "to": child.path
        }
      }, [_vm._v(_vm._s(child.title))]);
    }), 1) : _vm._e()];
  })], 2) : _vm.className == 'gnb' ? _c('v-row', {
    staticClass: "row--lg",
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": item.path,
        "text": "",
        "x-large": "",
        "color": "grey darken-4"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
  })], 2) : _vm.className == 'gnb-mo' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnbs, function (item) {
    return [_c('li', {
      key: item.title,
      class: _vm.className + '__list'
    }, [_c('router-link', {
      class: _vm.className + '__list-link',
      attrs: {
        "to": item.path
      }
    }, [_vm._v(_vm._s(item.title))])], 1)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }