var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "content-class": "chat-area elevation-0 transparent"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-sheet', _vm._b({
    staticClass: "chat-area__inner"
  }, 'v-sheet', {
    loading: _vm.loading
  }, false), [_c('v-tabs-items', {
    staticClass: "fill-height transparent",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('tab-room-list', _vm._g(_vm._b({
    attrs: {
      "value": "list"
    }
  }, 'tab-room-list', {
    tab: _vm.tab,
    rooms: _vm.rooms
  }, false), {
    join: _vm.join,
    back: _vm.back,
    close: _vm.close
  })), _c('tab-room-view', _vm._g(_vm._b({
    attrs: {
      "value": "view"
    }
  }, 'tab-room-view', {
    tab: _vm.tab,
    room: _vm.room,
    typing: _vm.typing,
    loading: _vm.loading
  }, false), {
    send: _vm.send,
    back: _vm.back
  }))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }