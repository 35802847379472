<template>
    <svg
        v-if="direction == 'up' || direction == 'down'"
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 16"
        class="icon-svg icon-arrow-long"
        :class="[size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M12.75,6.77c-.33,.32-.87,.32-1.2,0L7.36,2.77V15.19c0,.45-.38,.81-.85,.81h-.02c-.47,0-.85-.36-.85-.81V2.77L1.45,6.77c-.33,.32-.87,.32-1.2,0-.33-.32-.33-.83,0-1.14L5.9,.24c.33-.32,.87-.32,1.2,0l5.65,5.39c.33,.32,.33,.83,0,1.14Z"
        />

        
    </svg>
    <svg
        v-else
        v-bind="$attrs"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 16"
        class="icon-svg icon-arrow-long"
        :class="[size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']"
    >
        <path
            d="M8 7.34351e-06L9.4 1.40001L3.8 7.00001L50 7L50 9L3.8 9.00001L9.4 14.6L8 16L1.39876e-06 8.00001L8 7.34351e-06Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
