<template>
    <v-btn :to="to" icon text color="grey darken-4">
        <slot v-bind:carts="carts"></slot>
    </v-btn>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        to: { type: String, default: null },
    },
    computed: {
        ...mapState(["carts"]),
    },
};
</script>
