import axios from "@/plugins/axios";

import bundleds from "./bundleds";
import options from "./options";
import relateds from "./relateds";
import supplies from "./supplies";

let url = "/api/console/shop/products";

export default {
    bundleds,
    options,
    relateds,
    supplies,

    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
