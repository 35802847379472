<template>
    <v-sheet class="d-flex flex-column" :class="{ 'vertical-table-row--hide-title': hideTitle }" style="flex: 1 0 auto">
        <v-row no-gutters align="center" class="txt txt--xs" :justify="justifySpaceBetween ? 'space-between' : undefined">
            <v-col cols="12" md="auto" v-bind="$attrs.headerProps">
                <div class="w-md-140px txt--dark font-weight-medium pt-16px pb-4px px-10px px-md-20px py-md-16px">
                    {{ term }} <span v-if="required" class="red--text">*</span>
                </div>
            </v-col>
            <v-col cols="12" :md="justifySpaceBetween ? 'auto' : ''" style="white-space: pre-line">
                <div class="pt-4px pb-16px px-10px px-md-20px py-md-16px">
                    <slot />
                </div>
            </v-col>
        </v-row>
        <v-divider v-if="!noDevider" />
    </v-sheet>
</template>

<script>
export default {
    props: {
        term: String,
        required: Boolean,
        noDevider: Boolean,
        justifySpaceBetween: Boolean,
        hideTitle: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.vertical-table-row--hide-title{
    margin-bottom: 20px;
    .row{
        >[class*="col"]:first-child{
            display: none;
        }
        >[class*="col"]{
            >*{
                padding: 0 !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .vertical-table-row--hide-title{
        margin-bottom: 40px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
