export const initShopProductRelated = (related = {}) => ({
    ...(related || {}),

    _product: related?._product ?? null,
    _option: related?._option ?? null,
    _supply: related?._supply ?? null,

    custom: {
        ...(related?.custom || {}),
        name: related?.custom?.name || null,
        price: related?.custom?.price || null,
    },

    enabled: related?.enabled ?? true,
    deleted: related?.deleted ?? false,

    tempId: related.tempId ?? Math.random(),
});
