var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": "이용약관",
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          isValid: _vm.isValid
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('div', [_c('tit-wrap-x-small', [_c('v-checkbox', _vm._b({
    staticClass: "v-size--large",
    attrs: {
      "readonly": "",
      "label": "약관에 전체 동의합니다."
    },
    on: {
      "click": _vm.toggleSelectedAll
    },
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary, _vm.$attrs), false))], 1), _c('v-divider'), _vm._l(_vm.termsList, function (term) {
    return _c('page-section', {
      key: term._id,
      staticClass: "page-section--xs pb-0"
    }, [_c('tit-wrap-x-small', {
      scopedSlots: _vm._u([{
        key: "titleContents",
        fn: function () {
          return [_vm._v(" " + _vm._s(term.subject) + "에 동의"), _c('span', {
            staticClass: "pl-4px",
            class: [term.necessary ? 'red--text' : 'grey--text text--lighten-1']
          }, [_vm._v("(" + _vm._s(term.necessary ? "필수" : "선택") + ")")])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-checkbox', _vm._b({
      attrs: {
        "value": term._id,
        "label": "약관동의"
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary, _vm.$attrs), false))], 1), _c('v-card', {
      attrs: {
        "outlined": "",
        "rounded": "md"
      }
    }, [_c('div', {
      staticClass: "pa-16px pa-md-20px mh-120px",
      staticStyle: {
        "overflow-y": "auto"
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(term.content.replace(/\n/gi, '<br/>'))
      }
    })])])], 1);
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.agree
    }
  }, [_vm._v(" 동의 ")])], 1)], 1)], 1)], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }