var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-heart",
    attrs: {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M14.6436 1C12.9368 1 11.2986 1.79455 10.2294 3.05014C9.16022 1.79455 7.52207 1 5.81526 1C2.79401 1 0.420166 3.37384 0.420166 6.3951C0.420166 10.103 3.75532 13.1243 8.80709 17.715L10.2294 19L11.6518 17.7052C16.7035 13.1243 20.0387 10.103 20.0387 6.3951C20.0387 3.37384 17.6649 1 14.6436 1ZM10.3275 16.2534L10.2294 16.3515L10.1313 16.2534C5.46213 12.0256 2.38202 9.22997 2.38202 6.3951C2.38202 4.43324 3.85341 2.96185 5.81526 2.96185C7.32589 2.96185 8.79728 3.93297 9.31717 5.27684H11.1515C11.6616 3.93297 13.133 2.96185 14.6436 2.96185C16.6055 2.96185 18.0768 4.43324 18.0768 6.3951C18.0768 9.22997 14.9967 12.0256 10.3275 16.2534Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }