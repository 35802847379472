var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$slots['tit'] ? _c('div', {
    staticClass: "tit-wrap tit-wrap--lg",
    class: this.$slots['subTit'] || _vm.titAlign != undefined ? _vm.titAlign : 'text-left'
  }, [_c('h4', {
    staticClass: "tit",
    class: [this.$slots['subTit'] ? 'd-sm-inline-flex align-center' : 'tit--sm', 'tit--' + _vm.titSize]
  }, [_vm._t("tit"), this.$slots['subTit'] ? _c('span', {
    staticClass: "d-block d-sm-inline-block txt txt txt--xs pt-8px pt-sm-0 pl-sm-8px"
  }, [_vm._t("subTit")], 2) : _vm._e()], 2), this.$slots['txt'] ? _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-135"
  }, [_vm._t("txt")], 2) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }