import axios from "@/plugins/axios";

import bonuses from "./bonuses";
import coupons from "./coupons";
import grades from "./grades";
import logs from "./logs";
import managers from "./managers";
import points from "./points";
import scores from "./scores";

let url = "/api/console/users";

export default {
    bonuses,
    coupons,
    grades,
    logs,
    managers,
    points,
    scores,

    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    putType(data) {
        return axios.put(`${url}/${data._id}/type`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
