var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    class: {
      'row--xs': true,
      hasCertification: _vm.hasCertification
    },
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "hide-details": "auto",
      "type": "number"
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    label: _vm.label,
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "hide-details": "auto",
      "type": "number"
    },
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "hide-details": "auto",
      "type": "number"
    },
    model: {
      value: _vm.phone3,
      callback: function ($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    readonly: _vm.readonly,
    dense: _vm.dense
  }), false), {
    input: _vm.input,
    click: _vm.click
  }))], 1), _vm.rules ? [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-phone-field-rules pa-0 ma-0",
    attrs: {
      "value": _vm.phone,
      "hide-details": "auto"
    }
  }, 'v-text-field', {
    rules: _vm.rules
  }, false))], 1)] : _vm._e(), _vm.hasCertification ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 w-sm-80px",
    attrs: {
      "x-large": _vm.dense == false ? true : false
    },
    on: {
      "click": _vm.certify
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary3, _vm.btnAttrs, _vm.attrs, {
    dense: _vm.dense
  }), false), [_vm._v("본인인증")])], 1), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.processCertifiedData
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }