import { PRODUCT_TYPES, PRODUCT_OPTION_CRITERIA_TYPES, PRODUCT_OPTION_SORTS } from "../constants";
import { initShopProductRelated } from "./shop-product-relateds.inits";

export const initProductOptionCriterionValue = (value = {}) => ({
    ...value,
    img: value.img || null,
    name: value.name || null,
    hexa: value.hexa || null,

    tempId: value.tempId || Math.random(),
});

export const initProductOptionCriterion = (criterion = {}) => ({
    ...criterion,
    type: criterion.type || PRODUCT_OPTION_CRITERIA_TYPES.OTHER.value,
    name: criterion.name || null,
    values: (criterion.values || []).map(initProductOptionCriterionValue),

    tempId: criterion.tempId || Math.random(),
});

export const initProductOptionsConf = (conf = {}) => ({
    ...(conf || {}),
    enabled: conf?.enabled || false,
    sort: conf?.sort || PRODUCT_OPTION_SORTS.LATEST.value,
    criteria: (conf?.criteria || []).map(initProductOptionCriterion),
});

export const initProduct = (product = {}) => ({
    ...product,

    _brand: product._brand || null,
    _category: product._category || null,

    _thumb: product._thumb || null,
    _images: [...(product._images || [])],
    images: [...(product.images || [])],

    _addOns: [...(product._addOns || [])],
    _options: [...(product._options || [])],
    _supplies: [...(product._supplies || [])],
    _relateds: [...(product._relateds || [])],
    _bundleds: [...(product._bundleds || [])],

    type: product.type ?? PRODUCT_TYPES.NORMAL_PRODUCT.value,
    code: product.code || null,
    name: product.name || null,
    content: product.content || null,

    cost: product.cost ?? 0,
    price: product.price ?? 0,
    stock: product.stock ?? 0,
    stockEnabled: product.stockEnabled || false,

    size: product.size || null,
    origin: product.origin || null,
    manufacturer: product.manufacturer || null,

    shows: product.shows ?? true,
    sells: product.sells ?? true,

    sequence: product.sequence || 0,

    flags: [...(product.flags || [])],
    groups: [...(product.groups || [])],
    labels: [...(product.labels || [])],
    hashtags: [...(product.hashtags || [])],
    relatedGroupCode: product.relatedGroupCode || null,

    addOns: [...(product?.addOns || [])],
    addOnsConf: {
        ...(product.addOnsConf || {}),
        enabled: product.addOnsConf?.enabled || false,
    },

    discountEnabled: product.discountEnabled ?? false,
    discount: {
        ...(product.discount || {}),
        unit: product.discount?.unit ?? "price",
        amount: product.discount?.amount ?? 0,
        expiration: {
            ...(product.discount?.expiration || {}),
            enabled: product.discount?.expiration?.enabled ?? false,
            startDate: product.discount?.expiration?.startDate ?? null,
            endDate: product.discount?.expiration?.endDate ?? null,
        },
    },

    options: product.options || [],
    optionsConf: initProductOptionsConf(product.optionsConf),

    relateds: (product?.relateds || []).map(initShopProductRelated),
    relatedsConf: {
        ...(product.relatedsConf || {}),
        enabled: product.relatedsConf?.enabled || false,
    },

    variation: {
        ...(product?.variation || {}),
        code: product.variation?.code || null,
        list: [...(product.variation?.list || [])],
        spec: [...(product.variation?.spec || [""])],
    },

    bundleds: (product?.bundleds || []).map(initShopProductRelated),
});

export const initProductOption = (option = {}) => ({
    ...option,
    hexa: option.hexa || null,
    name: option.name || null,
    value: option.value || null,
    price: option.price || 0,
    stock: option.stock || 0,
    enabled: option.enabled,

    tempId: option.tempId || Math.random(),
    tempId__color: option.tempId__color || null,
});
