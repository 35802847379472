var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer txt txt--xs grey darken-4"
  }, [_c('div', {
    staticClass: "py-20px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "footer__link",
    attrs: {
      "to": "/terms",
      "text": "",
      "small": "",
      "color": "white"
    }
  }, [_vm._v("이용약관")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "white h-12px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "footer__link",
    attrs: {
      "to": "/privacy-policy",
      "text": "",
      "small": "",
      "color": "white"
    }
  }, [_vm._v("개인정보처리방침")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "white h-12px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "footer__link",
    attrs: {
      "to": "/email-policy",
      "text": "",
      "small": "",
      "color": "white"
    }
  }, [_vm._v("이메일무단수집거부")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-layout', [_c('v-btn', {
    attrs: {
      "href": "https://www.youtube.com/user/dentiumworld",
      "target": "_blank",
      "icon": "",
      "text": "",
      "color": "white"
    }
  }, [_c('icon-youtube')], 1), _c('v-btn', {
    attrs: {
      "href": "https://instagram.com/dentium_korea?igshid=MzRlODBiNWFlZA==",
      "target": "_blank",
      "icon": "",
      "text": "",
      "color": "white"
    }
  }, [_c('icon-instar')], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "footer__family-site w-180px",
    attrs: {
      "items": _vm.sites,
      "placeholder": "Family Site",
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-list-item', {
          on: {
            "click": function ($event) {
              return _vm.openLink(item.link);
            }
          }
        }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
      }
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.15)"
    }
  }), _c('div', {
    staticClass: "py-30px py-md-42px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "grey darken-4",
      "max-width": "640"
    }
  }, [_c('p', {
    staticClass: "font-weight-medium white--text mb-10px mb-md-16px"
  }, [_vm._v("브라이트 임플란트")]), _c('v-row', {
    staticClass: "ma-n2px mx-md-n6px"
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "pa-2px px-md-6px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "txt--light"
    }, [_vm._v(_vm._s(item.title) + " : " + _vm._s(item.info))])]);
  }), 1)], 1)], 1), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.15)"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "auto"
    }
  }, [_c('p', {
    staticClass: "font-weight-medium white--text mb-6px mb-md-8px"
  }, [_vm._v("고객센터")]), _c('a', {
    staticClass: "font-size-20 font-size-md-36 font-weight-bold d-block white--text line-height-1 mb-10px mb-md-16px",
    attrs: {
      "href": "tel: 0800502875",
      "target": "_blank"
    }
  }, [_vm._v(" 080-050-2875 ")]), _c('v-row', {
    staticClass: "ma-n2px mx-md-n6px"
  }, _vm._l(_vm.time, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "pa-2px px-md-6px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "txt--light"
    }, [_vm._v(_vm._s(item.title) + " : " + _vm._s(item.info))])]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.15)"
    }
  }), _c('div', {
    staticClass: "py-20px py-md-30px"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('p', {
    staticClass: "txt--light"
  }, [_vm._v("Copyright 2023. The Bright CO.,Ltd. All Rights Reserved.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }