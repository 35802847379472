<template>
    <component :is="SkinComponent" v-model="form" v-bind="{ code, loading }" v-on="{ save }" />
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { initForm } from "@/assets/variables";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data: () => ({
        form: initForm(),

        loading: false,
    }),

    computed: {
        ...mapState(["accessToken"]),
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },

    mounted() {
        this.init();
    },

    watch: {
        accessToken() {
            this.init();
        },
    },

    methods: {
        async init() {
            const { code } = this;

            if (this.accessToken) {
                const { user: writer } = await api.v1.me.get();
                const { name, phone, email, postcode, address1, address2 } = writer;
                this.form = initForm({
                    ...this.form,
                    code,
                    writer,
                    meta: {
                        name,
                        phone,
                        email,
                        postcode,
                        address1,
                        address2,
                        ...this.form.meta,
                    },
                });
            } else this.form = initForm({ ...this.form, code });
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { ...form } = this.form;
                await api.v1.forms.post(form);

                alert("저장되었습니다");

                this.$router.go(-1);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
