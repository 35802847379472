import axios from "@/plugins/axios";
import router from "../router";

import auth from "./auth";
import console from "./console";
import dentium from "./dentium";
import editor from "./editor";
import { plugins } from "./plugins";
import v1 from "./v1";

export default {
    auth,
    console,
    dentium,
    editor,
    plugins,
    v1,
    getResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => new File([result.data], isShort ? filename?.split("/")?.pop() : filename));
    },
    getMemberResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            try {
                return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
            } catch (error) {
                var blob = new Blob([result.data]);
                blob.name = filename;
                return blob;
            }
        });
    },
    downloadMemberResource(url, name = url.split("/").pop()) {
        return axios({
            url,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(result.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    },
};
