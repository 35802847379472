var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('div', {
    staticClass: "header-contents"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("브라이트 임플란트")])])], 1)])]), _c('v-col', [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [!_vm.accessToken ? [_c('v-btn', {
    staticClass: "header-quick__btn",
    attrs: {
      "to": "/login",
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_c('icon-person')], 1), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("로그인")])] : [_c('v-btn', {
    staticClass: "header-quick__btn",
    attrs: {
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('icon-logout')], 1), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("로그아웃")])]], 2), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-quick__btn",
    attrs: {
      "to": "/mypage/orders",
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_c('icon-person')], 1), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("마이페이지")])], 1) : _vm._e(), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('main-cart-button', {
    staticClass: "header-quick__btn",
    attrs: {
      "to": "/mypage/cart"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var carts = _ref.carts;
        return [_c('span', [_c('v-badge', {
          attrs: {
            "value": carts.length != 0,
            "dot": "",
            "bordered": "",
            "overlap": "",
            "color": "red"
          }
        }, [_c('icon-shopping-bag')], 1)], 1)];
      }
    }], null, false, 1559128182)
  }), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("장바구니")])], 1) : _vm._e(), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-quick__btn",
    attrs: {
      "to": "/mypage/likes",
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_c('icon-heart')], 1), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("찜하기")])], 1) : _vm._e(), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "header-quick__btn",
    attrs: {
      "to": "/mypage/questions",
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_c('icon-chat')], 1), _c('p', {
    staticClass: "header-quick__text"
  }, [_vm._v("문의하기")])], 1) : _vm._e()], 1)], 1)], 1)], 1)])], 1)]), _c('div', {
    staticClass: "mobile-header"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("브라이트 임플란트")])])], 1)])]), _vm._m(0), _c('div', {
    staticClass: "mobile-sidebar"
  }, [_c('div', {
    staticClass: "mobile-sidebar-head"
  }, [_vm._m(1), _c('div', {
    staticClass: "mobile-sidebar__info"
  }, [!_vm.accessToken ? _c('router-link', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인 해주세요")]) : _c('a', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])], 1)]), _c('div', {
    staticClass: "mobile-sidebar-body"
  }, [_vm.accessToken ? _c('ul', {
    staticClass: "mobile-sidebar__mylink"
  }, [_c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/orders"
    }
  }, [_c('icon-person'), _c('span', [_vm._v("마이페이지")])], 1)], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/likes"
    }
  }, [_c('icon-heart'), _vm._v(" 찜하기 ")], 1)], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/cart"
    }
  }, [_c('icon-shopping-bag'), _vm._v(" 장바구니 ")], 1)], 1), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/questions"
    }
  }, [_c('icon-chat'), _vm._v(" 문의하기 ")], 1)], 1)]) : _vm._e(), _c('client-gnb', {
    attrs: {
      "className": "gnb-mo"
    }
  })], 1)]), _c('div', {
    staticClass: "mobile-header-dimmed"
  })]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-hamburger"
  }, [_c('button', {
    staticClass: "mobile-hamburger__btn",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span', {
    staticClass: "mobile-hamburger__line"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "mobile-sidebar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 메뉴바 닫기")])]);

}]

export { render, staticRenderFns }