import { USER_LEVEL_CRITERIA_TYPES, USER_TYPES } from "../constants/";

export const initUserLevelCriterion = (criterion = {}) => ({
    ...criterion,

    type: criterion.type ?? USER_LEVEL_CRITERIA_TYPES.PRODUCT_PURCHASE_AMOUNT.value,
    value: criterion.value ?? 0,
    isActive: criterion.isActive ?? false,
});

export const initUserLevel = (level = {}) => ({
    ...level,

    code: level.code ?? USER_TYPES.PERSON.value,
    name: level.name ?? null,
    index: level.index ?? null,
    scope: [...(level.scope || [])],

    isActive: level.isActive ?? false,
    isWelcome: level.isWelcome ?? false,

    criteria: [...(level.criteria || [])].map(initUserLevelCriterion),

    // virtual
    points: [...(level.points || [])],
    coupons: [...(level.coupons || [])],
});
