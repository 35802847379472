var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', _vm._b({
    staticClass: "fill-height"
  }, 'v-tab-item', {
    value: _vm.value
  }, false), [_c('v-card', {
    staticClass: "chat-area__card",
    attrs: {
      "color": "grey darken-4",
      "elevation": "10"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-24px"
  }, [_c('v-row', {
    staticClass: "white--text",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-4px",
    attrs: {
      "text": "",
      "icon": "",
      "tile": "",
      "x-small": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-chevron-left")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm white--text"
  }, [_vm._v("Helper")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    staticClass: "v-size--xx-large",
    attrs: {
      "color": "#FFB82F"
    }
  }, [_c('i', {
    staticClass: "icon icon-chatbot"
  })])], 1)], 1)], 1), _c('v-list', {
    staticClass: "chat-list--group"
  }, [_vm._l(_vm.messages, function (message, index) {
    return [_c('v-list-item', {
      key: `${message._id}--query`,
      staticClass: "chat-list chat-list--right",
      class: index !== 0 ? 'mt-8px mt-md-16px' : ''
    }, [_c('v-card', {
      staticClass: "chat-list__card",
      attrs: {
        "color": "#F2F6FF"
      }
    }, [_c('div', {
      staticClass: "pa-12px pa-md-16px"
    }, [_c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(_vm._s(message === null || message === void 0 ? void 0 : message.query))])])])], 1), _c('v-list-item', {
      key: `${message._id}--reply`,
      staticClass: "chat-list chat-list--left",
      class: index !== 0 ? 'mt-8px mt-md-16px' : ''
    }, [_c('v-card', {
      staticClass: "chat-list__card",
      attrs: {
        "color": "grey lighten-5"
      }
    }, [_c('div', {
      staticClass: "pa-12px pa-md-16px"
    }, [_c('v-row', {
      staticClass: "row--xs flex-nowrap"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      attrs: {
        "color": "#FFB82F",
        "size": "24"
      }
    }, [_c('i', {
      staticClass: "icon icon-chatbot icon-chatbot--sm"
    })])], 1), _c('v-col', {
      staticClass: "chat-list__replay"
    }, [!(message !== null && message !== void 0 && message.reply) ? _c('div', {
      staticClass: "chat-list__loading"
    }, [_c('v-progress-linear', {
      attrs: {
        "stream": "",
        "indeterminate": "",
        "buffer-value": "0"
      }
    })], 1) : _c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(_vm._s(message === null || message === void 0 ? void 0 : message.reply))])])], 1)], 1)])], 1)];
  }), !_vm.messages.length ? [_c('v-list-item', {
    staticClass: "chat-list"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--light"
  }, [_vm._v("No messages, yet")])])] : _vm._e()], 2), _c('v-card-actions', {
    staticClass: "grey lighten-1"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "flat": "",
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "placeholder": "내용을 입력하세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.send.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', _vm._b({
          staticClass: "mr-n4 v-size--xx-large",
          attrs: {
            "text": "",
            "icon": ""
          },
          on: {
            "click": _vm.send
          }
        }, 'v-btn', {
          loading: _vm.loading,
          disabled: _vm.typing
        }, false), [_c('v-icon', {
          attrs: {
            "color": "grey darken-4"
          }
        }, [_vm._v("mdi-send")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }, 'v-text-field', {
    loading: _vm.loading
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }