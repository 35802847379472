export const PAYMENT_METHODS = {
    card: { value: "card", text: "신용카드", PayType: "카드결제" },
    easy: { value: "easy", text: "간편결제", PayType: "카드결제" },
    kakao: { value: "kakao", text: "카카오페이" },
    account: { value: "account", text: "무통장입금" },
    advance: { value: "advance", text: "선수금", PayType: "선수금" },
};

export const ORDER_STATES = {
    CANCELLED: { value: "CANCELLED", text: "주문취소" },
    PURCHASED: { value: "PURCHASED", text: "주문접수" },
    CONFIRMED: { value: "CONFIRMED", text: "주문완료" },
    DISPATCHD: { value: "DISPATCHD", text: "발송완료" },
    DELIVERED: { value: "DELIVERED", text: "배송완료" },
};
