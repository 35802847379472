var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section"
  }, [this.$slots['mainContainerImmersive'] ? _vm._t("mainContainerImmersive") : _vm._e(), [_c('v-container', {
    staticClass: "container--lg"
  }, [_vm._t("default")], 2)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }