<template>
    <v-row class="products-items row--xl mx-xl-n90px">
        <v-col v-for="item in product" :key="item.title" cols="12" md="6" class="px-xl-90px">
            <router-link :to="item.link" class="products-item">
                <v-card rounded>
                    <v-img :src="item.image" max-width="710" :aspect-ratio="710 / 472" class="w-100"/>
                </v-card>
                <div class="products-item__contents pl-20px pl-md-30px pl-lg-40px ml-12px ml-md-30px ml-lg-40px">
                    <p class="txt txt--sm primary--text font-secondary font-weight-bold">{{item.titleAdd}}</p>
                    <h3 class="tit">{{item.title}}</h3>
                    <p class="txt txt--lg txt--dark mt-12px mt-md-24px">
                        {{item.txt}}
                    </p>
                </div>
            </router-link>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            product : [
                { 
                    title : "bright Tissue Level",
                    titleAdd : "Narrow Ridge",
                    txt : "깊고, 얇은 나사선 구조로 초기 고정력이 강합니다.",
                    link : "/product/tissue",
                    image : "/images/main/products-img.jpg",
                },
                { 
                    title : "bright Bone Level",
                    titleAdd : "Short Ridge",
                    txt : "낮은 골 높이에도 안전한 수술이 가능합니다.",
                    link : "/product/bone",
                    image : "/images/main/products-img2.jpg",
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.products-item{
    --rounded: 8px;
    display: flex;
    flex-direction: column;
    &__contents{
        padding-top: 12px;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: calc(100% + 30px);
            background-color: var(--v-grey-lighten3);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .products-item{
        --rounded: 12px;
        &__contents{
            padding-top: 30px;
            &::before{
                height: calc(100% + 60px);
            }
        }
    }
    .products-items{
        >[class*="col"]:nth-child(2n){
            .products-item{
                flex-direction: column-reverse;
                &__contents{
                    padding-top: 0 !important;
                    padding-bottom: 30px;
                    &::before{
                        bottom: unset;
                        top: 0;
                    }
                }
            }
        }
    }
}
@media (min-width:1024px){
    .products-item{
        &__contents{
            padding-top: 40px;
            &::before{
                height: calc(100% + 80px);
            }
        }
    }
    .products-items{
        >[class*="col"]:nth-child(2n){
            .products-item{
                &__contents{
                    padding-bottom: 40px;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>