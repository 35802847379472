var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-media"
  }, [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('v-responsive', {
    staticClass: "w-100",
    attrs: {
      "aspect-ratio": 924 / 520
    }
  }, [_c('iframe', {
    staticClass: "w-100",
    attrs: {
      "src": "https://www.youtube.com/embed/NE1XZHUTKzQ?si=TspTkKYqiZ1HwlIs",
      "title": "[덴티움 X 송강호] 덴티움엔 다 계획이 있구나, 22년 전부터라니(30)",
      "width": "100%",
      "height": "100%",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }