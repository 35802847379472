var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "v-size--xx-large scroll-top",
    attrs: {
      "elevation": "4",
      "icon": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "white",
      "transition": "slide-x-transition"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, [_c('icon-scroll-top', {
    staticClass: "grey--text text--darken-1"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }