var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    staticClass: "v-btn--arrow",
    class: 'v-size--' + _vm.size + ' v-btn--arrow--' + _vm.pointcolor,
    attrs: {
      "text": "",
      "color": "grey darken-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'v-btn', _vm.$attrs, false), [!this.$slots['default'] ? _c('span', [_vm._v("More view")]) : _vm._t("default"), _c('icon-arrow-long', {
    class: _vm.size == 'xx-large' ? 'ml-10px ml-md-32px' : 'ml-10px ml-md-16px',
    attrs: {
      "direction": "right"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }