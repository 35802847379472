import { io } from "socket.io-client";

/** @type {(import("vuex").Module)} */
export const siteHelpChat = {
    namespaced: true,

    state: {
        route: undefined,
        socket: undefined,
        session: {
            id: localStorage.getItem("sessionId") || null,
            name: localStorage.getItem("sessionName") || null,
        },

        room: undefined,
        rooms: [],
        summary: { totalCount: 0 },

        loading: false,
    },
    mutations: {
        setRoute(state, { route }) {
            state.route = route;
        },
        setSocket(state, { socket }) {
            state.socket = socket;
        },
        setSession(state, { session }) {
            state.session = session;
        },

        setRoom(state, { room }) {
            state.room = room;
        },

        setLoading(state, { loading }) {
            state.loading = loading;
        },
    },
    actions: {
        async init({ commit, dispatch, state, rootState }, route) {
            if (!!state.socket) return;
            if (state.loading) return;
            else commit("setLoading", { loading: true });

            commit("setRoute", { route });

            const { accessToken } = rootState;
            const sessionId = localStorage.getItem("sessionId");
            const sessionName = localStorage.getItem("sessionName");

            const options = {
                transports: ["websocket"],
                auth: { accessToken, sessionId, sessionName },
            };
            if (!accessToken) delete options.auth.accessToken;
            if (!sessionId) delete options.auth.sessionId;
            if (!sessionName) delete options.auth.sessionName;

            const socket = io(route, options);

            socket.on("session", ({ id, name }) => {
                localStorage.setItem("sessionId", id);
                localStorage.setItem("sessionName", name);
                commit("setSession", { session: { id, name } });

                socket.auth = { sessionId: id, sessionName: name };
                commit("setSocket", { socket });
                commit("setLoading", { loading: false });

                dispatch("joinRoom");
            });

            socket.on("joinRoom", ({ room }) => {
                commit("setRoom", { room });
                commit("setLoading", { loading: false });
            });

            socket.on("updateRoomItem", ({ room }) => {
                dispatch("updateRoom", { room });
            });

            socket.on("sendTermsAgreement", ({ room }) => {
                commit("setRoom", { room });
                commit("setLoading", { loading: false });
            });

            socket.on("sendMessage", ({ message }) => {
                dispatch("updateMessageItem", { message });
            });

            socket.on("sendRoomUserTyping", ({ _room, isUserTyping }) => {
                dispatch("updateRoom", { room: { _id: _room, isUserTyping } });
            });

            socket.on("sendRoomManagerTyping", ({ _room, isManagerTyping }) => {
                dispatch("updateRoom", { room: { _id: _room, isManagerTyping } });
            });

            commit("setSocket", { socket });
        },

        joinRoom({ state, commit }) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("joinRoom");
            commit("setLoading", { loading: true });
        },

        updateRoom({ state, commit }, { room }) {
            const isRoom = state.room?._id == room?._id;
            if (isRoom) commit("setRoom", { room: { ...state.room, ...room, updatedAt: room.updatedAt || Date.now() } });
        },

        updateMessageItem({ state, commit, dispatch }, { message }) {
            const isRoom = state.room?._id == message?._room;
            if (isRoom) {
                const index = state.room.messages.findIndex(({ _id, tempId }) => _id == message?._id || tempId == message?.tempId);

                if (0 <= index) state.room.messages.splice(index, 1, message);
                else state.room.messages.push(message);

                commit("setRoom", { room: { ...state.room } });
            }
        },

        sendTermsAgreement({ commit, state }, agrees) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;

            commit("setLoading", { loading: true });

            const _room = state.room?._id;
            socket.emit("sendTermsAgreement", { _room, agrees });
        },

        sendMessage({ state }, { _room, message } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("sendMessage", { _room, message });
        },

        sendRoomUserTyping({ state }, { _room, isUserTyping } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("sendRoomUserTyping", { _room, isUserTyping });
        },
    },
};
