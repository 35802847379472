var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [!_vm.hideTitle ? _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.items, function (item, index) {
    var _item$cols;
    return _c('v-col', {
      key: index,
      staticClass: "d-flex flex-column",
      attrs: {
        "cols": "12",
        "lg": (_item$cols = item === null || item === void 0 ? void 0 : item.cols) !== null && _item$cols !== void 0 ? _item$cols : _vm.defaultCols
      }
    }, [_vm.isArray(item) ? _vm._l(item, function (child) {
      return _c('vertical-table-row', _vm._b({
        key: child.term,
        attrs: {
          "hideTitle": _vm.hideTitle,
          "noDevider": _vm.hideTitle
        }
      }, 'vertical-table-row', child, false), [child.data ? [_vm._v(" " + _vm._s(child.data) + " ")] : _vm.$slots[child.term] ? _vm._t(child.term) : child.node ? _c('v-node', {
        attrs: {
          "node": child.node
        }
      }) : _vm._e()], 2);
    }) : [_c('vertical-table-row', _vm._b({
      attrs: {
        "hideTitle": _vm.hideTitle,
        "noDevider": _vm.hideTitle
      }
    }, 'vertical-table-row', item, false), [item.data ? [_vm._v(" " + _vm._s(item.data) + " ")] : _vm.$slots[item.term] ? _vm._t(item.term) : item.node ? _c('v-node', {
      attrs: {
        "node": item.node
      }
    }) : _vm._e()], 2)]], 2);
  }), 1), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }