var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "to": _vm.to,
      "icon": "",
      "text": "",
      "color": "grey darken-4"
    }
  }, [_vm._t("default", null, {
    "carts": _vm.carts
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }