import { ISSUE_ROUND_TYPES, ISSUE_TARGET_TYPES, ISSUE_TYPES, USAGE_TARGET_PRICES, USAGE_TARGET_TYPES } from "../constants";

export const initBank = (bank = {}) => ({
    ...bank,
    code: bank?.code || null,
    accountNumber: bank?.accountNumber || null,
    accountHolder: bank?.accountHolder || null,
});

export const initGeolocation = (geolocation = {}) => ({
    ...geolocation,
    type: geolocation?.type ?? "Point",
    coordinates: [geolocation?.coordinates ?? [127.032693842117, 37.4835924256371]].flat(),
});

export const initIssue = (issue = {}) => ({
    ...issue,
    type: issue.type ?? ISSUE_TYPES.INSTANT.value,

    event: {
        ...(issue.event || {}),
        type: issue.event?.type ?? null,
        code: issue.event?.code ?? null,
    },

    round: {
        ...(issue.round || {}),
        type: issue.round?.type ?? ISSUE_ROUND_TYPES.SINGLE.value,
        includeds: [...(issue.round?.includeds || [])],
        excludeds: [...(issue.round?.excludeds || [])],
    },

    target: {
        ...(issue.target || {}),
        type: issue.target?.type ?? ISSUE_TARGET_TYPES.ALL.value,
        _users: [...(issue.target?._users || [])],
        _levels: [...(issue.target?._levels || [])],
        userTypes: [...(issue.target?.userTypes || [])],
    },

    limit: {
        ...(issue.limit || {}),
        shop: {
            ...(issue.limit?.shop || {}),
            count__sum: issue.limit?.shop?.count__sum ?? 0,
            price__sum: issue.limit?.shop?.price__sum ?? 0,
        },
    },

    endsAt: issue.endsAt ?? null,
    startsAt: issue.startsAt ?? null,
    issuesAt: issue.issuesAt ?? null,

    isActive: issue.isActive ?? null,
});

export const initUsage = (usage = {}) => ({
    ...usage,

    type: usage.type ?? null,

    target: {
        ...(usage.target || {}),

        type: usage.target?.type ?? USAGE_TARGET_TYPES.ALLITEMS.value,
        price: usage.target?.price ?? USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value,

        _products: [...(usage.target?._products || [])],
        _categories: [...(usage.target?._categories || [])],
        _exhibitions: [...(usage.target?._exhibitions || [])],
        displayCodes: [...(usage.target?.displayCodes || [])],
    },

    limit: {
        ...(usage.limit || {}),
        shop: {
            ...(usage.limit?.shop || {}),
            count__sum: usage.limit?.shop?.count__sum ?? 0,
            price__sum: usage.limit?.shop?.price__sum ?? 0,
        },
        target: {
            ...(usage.limit?.target || {}),
            price__min: usage.limit?.target?.price__min ?? 0,
            price__max: usage.limit?.target?.price__max ?? 0,
        },
    },

    endsAt: usage.endsAt ?? null,
    startsAt: usage.startsAt ?? null,

    isActive: usage.isActive ?? null,
    combinable: usage.combinable ?? null,
});
