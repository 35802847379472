var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 13 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M12.75,6.77c-.33,.32-.87,.32-1.2,0L7.36,2.77V15.19c0,.45-.38,.81-.85,.81h-.02c-.47,0-.85-.36-.85-.81V2.77L1.45,6.77c-.33,.32-.87,.32-1.2,0-.33-.32-.33-.83,0-1.14L5.9,.24c.33-.32,.87-.32,1.2,0l5.65,5.39c.33,.32,.33,.83,0,1.14Z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-long",
    class: [_vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M8 7.34351e-06L9.4 1.40001L3.8 7.00001L50 7L50 9L3.8 9.00001L9.4 14.6L8 16L1.39876e-06 8.00001L8 7.34351e-06Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }