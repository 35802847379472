<template>
    <footer class="footer txt txt--xs grey darken-4">
        <div class="py-20px">
            <v-container class="container--lg">
                <v-row align="center">
                    <v-col cols="12" md="">
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-btn to="/terms" text small color="white" class="footer__link">이용약관</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-divider vertical class="white h-12px" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn to="/privacy-policy" text small color="white" class="footer__link">개인정보처리방침</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-divider vertical class="white h-12px" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn to="/email-policy" text small color="white" class="footer__link">이메일무단수집거부</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-row align="center" class="row--sm">
                            <v-col cols="auto">
                                <v-layout>
                                    <v-btn href="https://www.youtube.com/user/dentiumworld" target="_blank" icon text color="white">
                                        <icon-youtube />
                                    </v-btn>
                                    <v-btn href="https://instagram.com/dentium_korea?igshid=MzRlODBiNWFlZA==" target="_blank" icon text color="white">
                                        <icon-instar />
                                    </v-btn>
                                </v-layout>
                            </v-col>
                            <v-col cols="auto">
                                <v-select v-bind="{ ...attrs_input }" :items="sites" placeholder="Family Site" class="footer__family-site w-180px" dark>
                                    <template #item="{ item }">
                                        <v-list-item @click="openLink(item.link)">
                                            <v-list-item-title>
                                                {{item.title}}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-divider style="border-color: rgba(255, 255, 255, 0.15)" />
        <div class="py-30px py-md-42px">
            <v-container class="container--lg">
                <v-row justify="space-between">
                    <v-col cols="12" lg="auto">
                        <v-sheet color="grey darken-4" max-width="640">
                            <p class="font-weight-medium white--text mb-10px mb-md-16px">브라이트 임플란트</p>
                            <v-row class="ma-n2px mx-md-n6px">
                                <v-col v-for="item in info" :key="item.title" cols="auto" class="pa-2px px-md-6px">
                                    <p class="txt--light">{{ item.title }} : {{ item.info }}</p>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col cols="auto" class="d-none d-lg-block">
                        <v-divider vertical style="border-color: rgba(255, 255, 255, 0.15)" />
                    </v-col>
                    <v-col lg="auto">
                        <p class="font-weight-medium white--text mb-6px mb-md-8px">고객센터</p>
                        <a href="tel: 0800502875" target="_blank" class="font-size-20 font-size-md-36 font-weight-bold d-block white--text line-height-1 mb-10px mb-md-16px"> 080-050-2875 </a>
                        <v-row class="ma-n2px mx-md-n6px">
                            <v-col v-for="item in time" :key="item.title" cols="auto" class="pa-2px px-md-6px">
                                <p class="txt--light">{{ item.title }} : {{ item.info }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-divider style="border-color: rgba(255, 255, 255, 0.15)" />
        <div class="py-20px py-md-30px">
            <v-container class="container--lg">
                <p class="txt--light">Copyright 2023. The Bright CO.,Ltd. All Rights Reserved.</p>
            </v-container>
        </div>
    </footer>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import IconYoutube from "@/components/client/icons/icon-youtube.vue";
import IconInstar from "@/components/client/icons/icon-instar.vue";
import IconArrowRounded from "../icons/icon-arrow-rounded.vue";
export default {
    components: {
        IconArrowRounded,
        IconYoutube,
        IconInstar,
    },
    data: () => {
        return {
            attrs_input,

            carts: [],

            searchKey: "name",
            searchValue: null,

            // scrollTop
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
            info: [
                { title: "상호", info: "(주)덴티움" },
                { title: "대표이사", info: "서승우" },
                { title: "사업자등록번호", info: "123-81-57732" },
                { title: "통신판매업신고", info: "2020-수원영동-1868" },
                { title: "의료기기판매업신고번호", info: "2020-3220033-00253" },
                { title: "개인정보 책임 관리자", info: "서승우" },
                { title: "주소", info: "경기도 수원시 영통구 창룡대로256번길 76, 1층 B102호(이의동, 덴티움 지식산업센터)" },
                { title: "팩스", info: "02-6211-4681" },
            ],
            time: [
                { title: "평일", info: "오전 09:00 ~ 오후 06:00" },
                { title: "점심", info: "오후 12:00 ~ 오후 01:00" },
                { title: "휴무", info: "토,일,공휴일" },
            ],
            sites : [
                {
                    title:"덴티움 홈페이지",
                    link:"https://dentium.co.kr"
                },
                {
                    title:"덴티움 블로그",
                    link:"https://blog.naver.com/dentium_blog"
                },
            ]
        };
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            this.$nextTick(function () {
                this.footer();
            });
        },

        // footer
        footer: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                scrollTop();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                }
                scrollAnimate();
            }

            // Scroll Top
            function scrollTop() {
                if (scrollPosition > window.innerHeight / 2) {
                    HTML.setAttribute("data-scroll-top", "true");
                } else {
                    HTML.removeAttribute("data-scroll-top");
                }
            }
        },        
        openLink(link) {
            window.open(link, "_blank");
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--small:not(.v-btn--icon).footer__link {
    padding: 0 10px;
    margin: 0 -10px;
}
::v-deep {
    .v-divider--vertical {
        align-self: center;
        vertical-align: unset;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
        background: transparent;
        input::placeholder {
            color: #fff !important;
        }
    }
}
.footer {
    &__family-site {
        &.v-text-field--outlined.v-input--dense {
            border-radius: 0;
        }
        &.white--text {
            border-color: var(--v-grey-base);
        }
    }
}
</style>
