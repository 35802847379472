<template>
    <v-tab-item v-bind="{ value }" class="fill-height">
        <v-card color="grey darken-4" elevation="10" class="chat-area__card">
            <div class="pa-16px pa-md-24px" :style="$vuetify.breakpoint.mdAndUp ? 'height: 112px;' : ''">                
                <v-row no-gutters class="white--text">
                    <v-col>
                        <v-row no-gutters>
                            <v-col cols="auto">
                                <v-btn text icon tile x-small color="white" class="mr-4px" @click="$emit('close')"> <v-icon large>mdi-chevron-left</v-icon></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <p class="txt txt--sm white--text mb-4px">Chat with</p>
                                <h4 class="tit tit--sm">AI Helper</h4>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <v-avatar color="#FFB82F" class="v-size--xx-large"><i class="icon icon-chatbot" /></v-avatar>
                    </v-col>
                </v-row>
            </div>
            <!-- v-if="rooms.length" -->
            <template>
                <v-list class="chat-list--group">
                    <template v-for="room in items">
                        <v-list-item :key="room._id" class="chat-list" @click="$emit('join', room)">
                            <div class="txt txt--sm">
                                <p class="txt--dark text-truncate">{{ room?.message__last?.query || "No messages" }}</p>
                                <p class="txt--light mt-4px mt-md-8px">{{ (room?.message__last?.createdAt || room?.createdAt).toDateTime() }}</p>
                            </div>
                        </v-list-item>
                    </template>
                </v-list>
            </template>
            <v-btn color="grey lighten-1" dark tile block class="chat-button" @click="$emit('join')">
                Start New Chat
                <v-spacer />
                <v-icon size="24" class="ml-2">mdi-send</v-icon>
            </v-btn>
        </v-card>
    </v-tab-item>
</template>

<script>
export default {
    props: {
        value: { type: String }, // tab
        tab: { type: String }, // current tab

        rooms: { type: Array, default: () => [] },
    },
    computed: {
        items() {
            return this.rooms.map((room) => ({ ...room, message__last: room?.messages?.[room?.messages?.length - 1] || room.message__last }));
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-list {
    &--group.v-list {
        padding: 0;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 228px;
        min-height: 228px;
    }
    &.v-list-item {
        padding: 16px;
        border-bottom: 1px solid var(--v-grey-lighten3);
        display: block;
    }
}
.chat-button.v-btn.v-size--default {
    font-weight: 700;
    font-size: 14px !important;
    height: 48px !important;
    padding: 0 16px !important;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .chat-list {
        &--group.v-list {
            max-height: 300px;
            min-height: 300px;
        }
        &.v-list-item {
            padding: 24px;
            border-bottom: 1px solid var(--v-grey-lighten3);
            display: block;
        }
    }
    .chat-button.v-btn.v-size--default {
        font-size: 16px !important;
        height: 60px !important;
        padding: 0 24px !important;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
