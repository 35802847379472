export const initShopCategory = (category = {}) => ({
    ...category,

    _id: category?._id,

    _parent: category?._parent,
    _parents: [...(category?._parents ?? [])],

    code: category?.code ?? null,
    name: category?.name ?? null,
    color: category?.color ?? null,
    depth: category?.depth ?? 1,
});
