<template>
    <v-app>
        <!-- S: BANNER -->
        <slot name="bandBanner" />
        <!-- E: BANNER -->

        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }" />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div v-if="this.$slots['default']" id="contents">
                <v-container>
                    <slot />
                </v-container>
                <!-- <v-container class="container--lg">
                    <div class="fixed-section">
                        <div class="fixed-contents">
                            <v-row class="row--contents flex-nowrap">
                                S: contents
                                <v-col cols="12" xl="auto" class="flex-grow-1 flex-shrink-1">
                                    <div class="mw-1330px">
                                        <slot />
                                    </div>
                                </v-col>
                                E: contents
                                S: 사이드 영역
                                <v-col cols="auto" class="align-self-stretch d-none d-xl-block">
                                    <v-divider vertical class="h-100 mr-n1px" />
                                </v-col>
                                <v-col cols="auto" class="d-none d-xl-block">
                                    <div class="fixed-contents__fixed">
                                        <main-aside v-if="!hasCustomAside" />
                                        <slot name="custom-aside" />
                                    </div>
                                    <div class="fixed-contents__fixed-con"></div>
                                </v-col>
                                E: 사이드 영역
                            </v-row>
                        </div>
                    </div>
                </v-container> -->
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->

        <scroll-top />

        <v-btn v-if="!showsAiPromptChat" href="https://pf.kakao.com/_PQTbs/chat" target="_blank" elevation="4" icon fixed bottom right color="FFB82F" class="kakao-btn v-size--xx-large" v-bind="attrs" v-on="on"></v-btn>

        <ai-prompt-chat v-if="showsAiPromptChat">
            <template #activator="{ attrs, on }">
                <v-btn elevation="4" icon fixed bottom right color="FFB82F" class="chat-btn v-size--xx-large" v-bind="attrs" v-on="on">
                    <div id="chat-btn" class="chat-btn__inner"></div>
                </v-btn>
            </template>
        </ai-prompt-chat>
        <!-- <site-help-chat>
            <template #activator="{ attrs, on }">
                <v-btn elevation="4" icon fixed bottom right color="FFB82F" class="chat-btn v-size--xx-large mb-16" v-bind="attrs" v-on="on">
                    <div id="chat-btn" class="chat-btn__inner"></div>
                </v-btn>
            </template>
        </site-help-chat> -->
        <!-- <chat-float>
            <template #activator="{ attrs, on }">
                <v-btn elevation="4" icon fixed bottom right color="FFB82F" class="chat-btn v-size--xx-large" v-bind="attrs" v-on="on">
                    <div id="chat-btn" class="chat-btn__inner"></div>
                </v-btn>
            </template>
        </chat-float> -->
    </v-app>
</template>

<script>
import { mapState } from "vuex";

// import MainAside from "@/components/client/inc/main-aside/main-aside.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import MainHeader from "@/components/client/inc/main-header.vue";
import ScrollTop from "@/components/client/inc/scroll-top.vue";
import AiPromptChat from "@/components/client/chats/ai-prompt-chat/ai-prompt-chat.vue";
// import SiteHelpChat from "@/components/client/chats/site-help-chat/site-help-chat.vue";
// import ChatFloat from "../chats/chat-float.vue";

import "@mdi/font/css/materialdesignicons.css"

export default {
    components: {
        // MainAside,
        MainFooter,
        MainHeader,
        ScrollTop,
        AiPromptChat,
        // SiteHelpChat,
        // ChatFloat,
    },
    props: {
        // hasCustomAside: { type: Boolean, default: false },
        disableScrollTop: { type: Boolean, default: false },
    },
    computed: {
        ...mapState(["user"]),
        showsAiPromptChat() {
            return ["uxitest"].includes(this.user?.username);
        },
    },
};
</script>

<style lang="scss" scoped>
.kakao-btn {
    z-index: 10;
    background-image: url(/images/icon/icon-kakao-chat.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
        bottom: 76px !important;
    }
}
.chat-btn {
    z-index: 10;
    background-image: url(/images/icon/icon-chat.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.v-btn {
        overflow: visible;
        &::before {
            display: none;
        }
    }
    &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
        bottom: 76px !important;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 26px;
        height: 24px;
        background-image: url(/images/icon/icon-chat-ani.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        animation: chatAni 1.2s infinite ease-out;
    }
    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
    }
}
@keyframes chatAni {
    0% {
        top: -2px;
    }
    50% {
        top: 2px;
    }
    100% {
        top: -2px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .kakao-btn {
        &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
            bottom: 84px !important;
        }
    }
    .chat-btn {
        &.v-btn--fixed.v-btn--bottom.v-btn--fixed.v-btn--right {
            bottom: 84px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.fixed-contents {
    // overflow: hidden;
    &__fixed-con {
        display: none;
        height: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .fixed-section {
        position: relative;
        display: block;
        &.active {
            .fixed-contents {
                &__fixed {
                    position: fixed;
                    top: 12px;
                    & + .fixed-contents__fixed-con {
                        display: block;
                    }
                }
            }
        }
        &.bottom {
            .fixed-contents {
                &__fixed {
                    position: absolute !important;
                    top: auto !important;
                    left: auto !important;
                }
                &__fixed-con {
                    display: block;
                }
            }
        }
    }
    .fixed-contents {
        position: relative;
        &__fixed,
        &__fixed-con {
            width: 290px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
