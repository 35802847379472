var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "inquiary-card",
    attrs: {
      "rounded": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-80px"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "hideTitle": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "문의유형",
      fn: function () {
        return [_c('v-btn-toggle', {
          staticClass: "v-btn-toggle--primary",
          attrs: {
            "color": "grey lighten-4"
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.category,
            callback: function ($$v) {
              _vm.$set(_vm.form, "category", $$v);
            },
            expression: "form.category"
          }
        }, [_c('v-btn', {
          attrs: {
            "rounded": "",
            "outlined": "",
            "x-small": "",
            "value": "제품상담"
          }
        }, [_vm._v("제품상담")]), _c('v-btn', {
          attrs: {
            "rounded": "",
            "outlined": "",
            "x-small": "",
            "value": "회원가입문의"
          }
        }, [_vm._v("회원가입문의")]), _c('v-btn', {
          attrs: {
            "rounded": "",
            "outlined": "",
            "x-small": "",
            "value": "배송/기타 문의"
          }
        }, [_vm._v("배송/기타 문의")])], 1)];
      },
      proxy: true
    }, {
      key: "foot",
      fn: function () {
        return [_c('terms-of-agreements-short', {
          on: {
            "isValid": function (isValid) {
              return _vm.isTermsValid = isValid;
            }
          },
          model: {
            value: _vm.form._terms,
            callback: function ($$v) {
              _vm.$set(_vm.form, "_terms", $$v);
            },
            expression: "form._terms"
          }
        }), _c('div', {
          staticClass: "mt-20px mt-md-40px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-240px",
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, {
          loading: _vm.loading,
          disabled: _vm.disabled
        }), false), [_vm._v("신청하기")])], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }