var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form ? _c('vertical-table', _vm._b({
    attrs: {
      "items": _vm.computedItems,
      "hideTitle": _vm.hideTitle
    },
    scopedSlots: _vm._u([{
      key: "foot",
      fn: function () {
        return [_vm._t("foot")];
      },
      proxy: true
    }], null, true)
  }, 'vertical-table', {
    loading: _vm.loading,
    itemsPerRow: _vm.itemsPerRow
  }, false), [_vm._l(_vm.computedItems, function (_ref) {
    var _props$btnAttrs;
    var term = _ref.term,
      type = _ref.type,
      key = _ref.key,
      keys = _ref.keys,
      lastKey = _ref.lastKey,
      props = _ref.props;
    return [_vm.$slots[term] ? [_vm._t(term, null, {
      "slot": term
    })] : _vm.mode == 'form' ? [type == 'address' ? [_c('v-address-field', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "btnAttrs": Object.assign({}, (props === null || props === void 0 ? void 0 : props.btnAttrs) || {}, {
          className: `${(props === null || props === void 0 ? void 0 : (_props$btnAttrs = props.btnAttrs) === null || _props$btnAttrs === void 0 ? void 0 : _props$btnAttrs.className) || ''} primary`
        })
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-address-field', props, false))] : _vm._e(), type == 'bank' ? [_c('v-bank-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-bank-field', props, false))] : _vm._e(), type == 'checkbox' ? [_c('v-checkbox', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-checkbox', props, false))] : _vm._e(), type == 'currency' ? [_c('v-currency-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-currency-field', props, false))] : _vm._e(), type == 'date' ? [_c('v-text-field', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "type": "date",
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-text-field', props, false))] : _vm._e(), type == 'dates' ? [_c('v-row', {
      key: key,
      staticClass: "row--xs",
      attrs: {
        "slot": term,
        "align": "center"
      },
      slot: term
    }, [_c('v-col', [_c('v-text-field', _vm._b({
      attrs: {
        "type": "date",
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.findData(keys)[lastKey]['startsAt'],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys)[lastKey], 'startsAt', $$v);
        },
        expression: "findData(keys)[lastKey]['startsAt']"
      }
    }, 'v-text-field', props, false))], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" ~ ")]), _c('v-col', [_c('v-text-field', _vm._b({
      attrs: {
        "type": "date",
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.findData(keys)[lastKey]['endsAt'],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys)[lastKey], 'endsAt', $$v);
        },
        expression: "findData(keys)[lastKey]['endsAt']"
      }
    }, 'v-text-field', props, false))], 1)], 1)] : _vm._e(), type == 'editor' ? [_c('naver-smarteditor', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'naver-smarteditor', props, false))] : _vm._e(), type == 'email' ? [_c('v-email-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-email-field', props, false))] : _vm._e(), type == 'file' ? [_c('v-file-input', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "hide-details": "auto"
      },
      on: {
        "change": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-file-input', props, false))] : _vm._e(), type == 'password' ? [_c('v-password-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-password-field', props, false))] : _vm._e(), type == 'phone' ? [_c('v-phone-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit,
        "certification": function (value) {
          return _vm.$emit('certification', value);
        }
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-phone-field', props, false))] : _vm._e(), type == 'rrn' ? [_c('v-rrn-field', _vm._b({
      key: key,
      attrs: {
        "slot": term
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-rrn-field', props, false))] : _vm._e(), type == 'select' ? [_c('v-select', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-select', props, false))] : _vm._e(), type == 'switch' ? [_c('v-switch', _vm._b({
      key: key,
      staticClass: "my-10 pl-4",
      attrs: {
        "slot": term,
        "inset": "",
        "dense": "",
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-switch', props, false))] : _vm._e(), type == 'text' ? [_c('v-text-field', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-text-field', props, false))] : _vm._e(), type == 'textarea' ? [_c('v-textarea', _vm._b({
      key: key,
      attrs: {
        "slot": term,
        "hide-details": "auto"
      },
      on: {
        "input": _vm.emit
      },
      slot: term,
      model: {
        value: _vm.findData(keys)[lastKey],
        callback: function ($$v) {
          _vm.$set(_vm.findData(keys), lastKey, $$v);
        },
        expression: "findData(keys)[lastKey]"
      }
    }, 'v-textarea', props, false))] : _vm._e()] : _vm._e()];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }