import Vue from "vue";
import VueRouter from "vue-router";
import axios from "@/plugins/axios";
import store from "@/store";

import Main from "@/pages/client/Main.vue";
import { BoardRoutes } from "./board.router";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 공통 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // Terms 페이지
        path: "/terms/:code",
        component: () => import(/* webpackChunkName: "terms" */ "../pages/client/terms/TermsView.vue"),
        props: true,
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartnerPage.vue"),
        props: true,
    },
    {
        // 쇼핑몰 메인 & 제품 리스트
        path: "/shop",
        redirect: { path: "/shop/products" },
        props: true,
        hasCustNo: true,
    },
    {
        // 쇼핑몰 메인 & 제품 리스트
        path: "/shop/products",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductListPage.vue"),
        props: true,
        hasCustNo: true,
    },
    {
        // 제품 뷰
        path: "/shop/products/:_product",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductViewPage.vue"),
        props: true,
        hasCustNo: true,
    },
    {
        // 기획전 목록
        path: "/shop/exhibitions",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionList.vue"),
        props: true,
    },
    {
        // 기획전 상세
        path: "/shop/exhibitions/:_exhibition",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionView.vue"),
        props: true,
    },
    // {
    //     // 브랜드 목록
    //     path: "/shop/brands",
    //     component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 브랜드 상세
    //     path: "/shop/brands/:code",
    //     component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandViewPage.vue"),
    //     props: true,
    // },
    // {
    //     // 온라인 쇼룸 목록
    //     path: "/shop/showrooms",
    //     component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
    //     props: true,
    // },
    // {
    //     // 온라인 쇼룸 목록
    //     path: "/shop/showrooms/:code",
    //     component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
    //     props: true,
    // },
    {
        // 주문
        path: "/shop/order",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/order.vue"),
        props: true,
    },
    {
        // 주문완료
        path: "/shop/result",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/result.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // SNS 로그인 검증
        path: "/login/sns-validate",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/sns-validate.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/join.vue"),
        props: true,
    },
    {
        // 마이페이지 - 대시보드
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 대시보드
        path: "/mypage/dashboard",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 마이페이지 - 장바구니
        path: "/mypage/cart",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "cart" }),
    },
    {
        // 마이페이지 - 장바구니
        path: "/mypage/cart/regular",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "regular" }),
    },
    {
        // 마이페이지 - 주문내역
        path: "/mypage/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageOrdersPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 주문내역
        path: "/mypage/legacy-orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageLegacyOrders.vue"),
        props: true,
    },
    {
        // 마이페이지 - 거래내역
        path: "/mypage/history",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageHistoryPage.vue"),
        props: true,
    },
    // {
    //     // 마이페이지 - 영수증
    //     path: "/mypage/receipt",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReceiptPage.vue"),
    //     props: true,
    // },
    // {
    //     // 마이페이지 - 쿠폰
    //     path: "/mypage/coupons",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 마이페이지 - 쿠폰
    //     path: "/mypage/coupons/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
    //     props: true,
    // },
    {
        // 마이페이지 - 찜한제품
        path: "/mypage/likes",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageLikesPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 선수금
        path: "/mypage/advance-payment",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageAdvancePaymentPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 적립금 내역
        path: "/mypage/points",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePointsPage.vue"),
        props: true,
    },
    {
        // 제품 문의
        path: "/mypage/inquiry",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquiry.vue"),
        props: true,
    },
    {
        // 마이페이지 - 회원 정보 수정
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 배송지 정보
        path: "/mypage/destinations",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDestinationsPage.vue"),
        props: true,
    },
    // {
    //     // 마이페이지 - 환불계좌 관리
    //     path: "/mypage/refund-account",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRefundAccountPage.vue"),
    //     props: true,
    // },
    {
        // 마이페이지 - 간편결제 관리
        path: "/mypage/easy-pay",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageEasyPay.vue"),
        props: true,
    },
    {
        // 마이페이지 - 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 마이페이지 - 나의 리뷰
        path: "/mypage/reviews",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReviewList.vue"),
        props: true,
    },
    {
        // 마이페이지 - 나의 제품 Q&A
        path: "/mypage/inquiries",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageInquireList.vue"),
        props: true,
    },
    {
        // 마이페이지 - 1:1 문의 내역
        path: "/mypage/questions",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageQuestionsPage.vue"),
        props: true,
    },
    {
        // product
        path: "/product",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/product/ProductGatePage.vue"),
        props: true,
    },
    {
        // product tissue
        path: "/product/tissue",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/product/ProductTissuePage.vue"),
        props: true,
    },
    {
        // product bone
        path: "/product/bone",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/product/ProductBonePage.vue"),
        props: true,
    },
    {
        // bright Academy
        path: "/academy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/academy/AcademyMainPage.vue"),
        props: true,
        hasCustNo: true,
    },
    {
        // bright Academy
        path: "/academy/:category",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/academy/AcademyListPage.vue"),
        props: true,
        hasCustNo: true,
    },
    {
        // library - software
        path: "/library",
        redirect: { path: "/library/catalog" },
    },
    {
        // library - software
        path: "/library/software",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterSoftwarePage.vue"),
        props: true,
        hasCustNo: true,
    },
    {
        // library - catalog
        path: "/library/catalog",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterCatalogPage.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/terms",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/TermsPage.vue"),
        props: true,
    },
    {
        // 이메일무단수집거부
        path: "/email-policy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/email-policy.vue"),
        props: true,
    },
    // {
    //     // IFU - downloads
    //     path: "/ifu/downloads",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDownLoadsPage.vue"),
    //     props: true,
    // },
    // {
    //     // IFU - 자주묻는 질문
    //     path: "/ifu/faq",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFaqPage.vue"),
    //     props: true,
    // },
    {
        // 고객센터 - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 자주묻는 질문
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFaqPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/inquiry",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/inquiry/:_form",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 개인회원서비스
        path: "/center/individual-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterIndividualServicePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 기업회원 서비스
        path: "/center/corporate-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterCorporateServicePage.vue"),
        props: true,
    },
    // {
    //     // 고객센터 - 첫 구매 가이드
    //     path: "/center/first-buying-guide",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFirstBuyingGuidePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 필독 가이드
    //     path: "/center/must-read-guide",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterMustReadGuidePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 설치 서비스
    //     path: "/center/installation-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInstallationServicePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 출장 컨설팅
    //     path: "/center/business-trip-consulting",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBusinessTripConsultingPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 온라인 컨설팅
    //     path: "/center/online-consulting",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterOnlineConsultingPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 딜리버리 설치 서비스
    //     path: "/center/delivery-installation-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDeliveryInstallationServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 전담 수리 서비스
    //     path: "/center/dedicated-repair-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDedicatedRepairServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 안심 상담
    //     path: "/center/consultation",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterConsultationPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 대량구매요청
    //     path: "/center/bulk-purchase",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBulkPurchasePage.vue"),
    //     props: true,
    // },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/plugins/nice/pay/result",
        component: () => import("../pages/plugins/nice/NICEPayResult.vue"),
        props: true,
    },
    // 페이유
    {
        path: "/plugins/nice/payu/request",
        component: () => import("../pages/plugins/nice/payu/PayuRequest.vue"),
        props: true,
    },
    {
        path: "/plugins/nice/payu/result",
        component: () => import("../pages/plugins/nice/payu/PayuResult.vue"),
        props: true,
    },
    {
        path: "/plugins/nice/payu/setting",
        component: () => import("../pages/plugins/nice/payu/PayuSetting.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 -  회원직급
        path: "/console/user-grades",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-grades/UserGradeList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/user-level",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원등급
        path: "/console/user-level/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원등급
        path: "/console/withdrawn-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/withdrawn-users/WithdrawnUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 목록
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 상세
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 영업담당자 설정
        path: "/console/user-managers",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserManagerForm.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 쿠폰
        path: "/console/user-coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-coupons/UserCouponList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 문의
        path: "/console/inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/inquiries/InquiryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 기본정보
        path: "/console/site/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteSettingPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 이용약관
        path: "/console/site/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteTermList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 대량등록
        path: "/console/shop/bulk-upload",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/bulk-upload/ShopBulkUpload.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 배송정보
        path: "/console/shop/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/setting/SettingView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 배송정책
        path: "/console/shop/shippings",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/shippings/ShopShippingList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 적립정책
        path: "/console/shop/points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/points/ShopPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품 - 목록
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품 - 신규
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품 - 상세
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/categories/CategoryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 브랜드
        path: "/console/shop/brands",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandList.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 쇼핑몰 - 쇼룸
    //     path: "/console/shop/showrooms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 쇼핑몰 - 쇼룸
    //     path: "/console/shop/showrooms/:code",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 쇼핑몰 - 기획전
        path: "/console/shop/exhibitions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/exhibitions/ShopExhibitionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품전시
        path: "/console/shop/display",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품전시
        path: "/console/shop/display/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 제품문의
        path: "/console/shop/inquires",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/inquires/InquireList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 구매후기
        path: "/console/shop/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/reviews/ReviewList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 통계
        path: "/console/statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/StatisticsView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 주문 관리
        path: "/console/shop/purchases/all",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PurchaseList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 주문 관리
        path: "/console/shop/orders",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/orders/ShopOrderList.vue"),
        props: true,
        scope: ["console", "manager"],
    },
    {
        // 관리자 - 주문 - 이전주문 관리
        path: "/console/legacy/orders",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/legacy/orders/LegacyOrderList.vue"),
        props: true,
        scope: ["console", "manager"],
    },
    {
        // 관리자 - 주문 - 미결제 관리
        path: "/console/shop/purchases/nonpayment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/NonpaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 발주/발송 관리
        path: "/console/shop/purchases/payment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 배송 관리
        path: "/console/shop/purchases/delivery",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/DeliveryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 취소 관리
        path: "/console/shop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CancelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 반품 관리
        path: "/console/shop/purchases/exchange",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ExchangeList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 교환 관리
        path: "/console/shop/purchases/return",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ReturnList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문 - 구매확정 관리
        path: "/console/shop/purchases/complete",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CompleteList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - AI챗봇
        path: "/console/center/prompts",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/prompts/PromptListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - AI챗봇
        path: "/console/center/prompts/:group",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/prompts/PromptListPage.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 문의 - 제휴문의
    //     path: "/console/center/inquiry-partners",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/InquiryPartnerList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 문의 - 문의하기
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/questions/QuestionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 문의 - 상담예약
        path: "/console/center/inquiry-calls",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/InquiryCallForms.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 메인페이지 - 배너 관리
        path: "/console/main/banner/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainBannerPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인페이지 - 제품전시 관리
        path: "/console/main/display/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인페이지 - 리뷰전시 관리
        path: "/console/main/review/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayReview.vue"),
        props: true,
        scope: ["console"],
    },

    // {
    //     // 관리자 - 정산관리
    //     path: "/console/user-settlements",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 정산상세
    //     path: "/console/user-settlements/:_settlement",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementView.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 적립금 관리
        path: "/console/user-points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-points/UserPointList.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 직급포인트 관리
    //     path: "/console/user-scores",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-scores/UserScoreList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 보너스 관리
    //     path: "/console/user-bonuses",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-bonuses/UserBonusList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 코드 관리
    //     path: "/console/shop/codes",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/codes/CodeList.vue"),
    //     props: true,
    // },
    // {
    //     // 관리자 - 쿠폰 관리
    //     path: "/console/shop/coupons",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/ShopCouponList.vue"),
    //     props: true,
    // },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notifications/NotificationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faqs/FaqList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - bright Academy - 카테고리
        path: "/console/academy/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/AcademyCategoryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - IFU - 카탈로그/소프트웨어
        path: "/console/shop/catalogues",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/catalogues/CatalogueListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - IFU - 소프트웨어 로그
        path: "/console/user-logs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-logs/UserLogList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 배너관리
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 띠배너관리
        path: "/console/band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popups/PopupList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/smtp-post",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/smtp/SmtpPostPage.vue"),
        props: true,
        scope: ["console"],
    },

    ...require("./counsel.router").routes,

    ////////////////////////////////////////////////////////////////////////////
    // 404 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////

    {
        // 관리자 - 팝업관리
        path: "*",
        component: () => import(/* webpackChunkName: "404" */ "../pages/client/PageNotFound.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        return new Promise((resolve) =>
            setTimeout(() => {
                if (to.hash) resolve({ selector: to.hash, behavior: "smooth" });

                if (savedPosition) {
                    resolve({ ...savedPosition, behavior: "smooth" });
                }

                if (to.path.includes("/shop/showrooms")) resolve();

                if (to?.path == from?.path) resolve();

                resolve({ x: 0, y: 0, behavior: "smooth" });
            }, 500)
        );
    },
});

router.beforeEach(async (to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => route.path == to.path || route.path == to?.matched?.[0]?.path);

        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근 권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근 권한이 없습니다");

            next();
        } else if (route?.hasCustNo) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });
            if (!store.state.user) await store.dispatch("getUser");
            if (!payload.scope.includes("console") && !store.state.user?.meta?.CustNo) {
                throw new Error("접근 권한이 없습니다");
            }

            next();
        } else next();
    } catch (error) {
        alert(error.message);

        let path;
        if (error.cause) {
            path = ["/mypage", "promoter", "/console"].find((path) => to.path.includes(path)) || "/";
            if (path == "/") path = error.cause;
        } else if (from.path != "/") path = routes.find((route) => route.path == from.path)?.path;
        else {
            path = ["/mypage", "promoter", "/console"].find((path) => to.path.includes(path)) || "/";
        }
        next({ path });
    }
});
export default router;
