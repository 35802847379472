import { SHIPPING_OPTION_BUNDLE_TYPES, SHIPPING_OPTION_CHARGE_TYPES, SHIPPING_CODES, SHIPPING_TARGET_TYPES } from "../constants";

export const initShopShippingOption__pricesRangeItem = (item = {}) => ({
    ...item,
    price: item.price ?? 0,
    amount: item.amount ?? 0,
});

export const initShopShippingOption__travelRangeItem = (item = {}) => ({
    ...item,
    distance: item.distance ?? 0,
    amount: item.amount ?? 0,
});

export const initShopShippingOption = (option = {}) => ({
    ...option,

    code: option?.code ?? SHIPPING_CODES.GENERAL_COURIER_DELIVERY.value,

    bundle: {
        ...(option?.bundle || {}),

        type: option?.bundle?.type || SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value,
    },

    charge: {
        ...(option?.charge || {}),

        type: option?.charge?.type || SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value,

        fixedAmount: option?.charge?.fixedAmount ?? 0,

        pricesRange: [...(option?.charge?.pricesRange || [])].map(initShopShippingOption__pricesRangeItem),

        travelRange: [...(option?.charge?.travelRange || [])].map(initShopShippingOption__travelRangeItem),
    },

    island: {
        ...(option?.island || {}),

        charge: {
            ...(option?.island?.charge || {}),
            amount__base: option?.island?.charge?.amount__base ?? 0,
            amount__jeju: option?.island?.charge?.amount__jeju ?? 0,
        },

        isActive: option?.island?.isActive ?? false,
    },

    service: {
        ...(option?.service || {}),

        charge: {
            ...(option?.service?.charge || {}),
            amount: option?.service?.charge?.amount ?? 0,
        },

        isActive: option?.service?.isActive ?? false,
    },

    tempId: option?.tempId ?? Math.random(),
});

export const initShopShipping = (shipping = {}) => ({
    ...shipping,

    name: shipping.name ?? null,

    endsAt: shipping.endsAt ?? null,
    startsAt: shipping.startsAt ?? null,

    target: {
        ...(shipping.target || {}),
        type: shipping.target?.type ?? SHIPPING_TARGET_TYPES.EVERY_ASSET.value,
        _products: [...(shipping.target?._products || [])],
        _categories: [...(shipping.target?._categories || [])],
    },

    isActive: shipping.isActive ?? false,

    options: [...(shipping.options || [{}])].map(initShopShippingOption),
});
