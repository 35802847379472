import Vue from "vue";
import Vuetify from "vuetify";
import VCurrencyField from "v-currency-field";
import colors from "vuetify/es5/util/colors";

// v-currency-field
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                base: "#013CA6",
                lighten5: "#B2CDFF",
                lighten4: "#669CFE",
                lighten3: "#2E78FE",
                lighten2: "#0152E3",
                lighten1: "#0147C4",
                darken1: "#013188",
                darken2: "#012669",
                darken3: "#001B4B",
                darken4: "#00102C",
            },
            secondary: {
                base: "#ACC90B",
                lighten5: "#F9FDE0",
                lighten4: "#EAF997",
                lighten3: "#E0F662",
                lighten2: "#D2F31E",
                lighten1: "#C5E60D",
                darken1: "#93AC09",
                darken2: "#7A8F08",
                darken3: "#617206",
                darken4: "#495505",
            },
            grey: {
                base: "#666",
                lighten5: "#f7f7f7",
                lighten4: "#ececec",
                lighten3: "#ddd",
                lighten2: "#aaa",
                lighten1: "#9A9FA2",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#2A2B2E",
            },
            anchor: "inherit",
            red: {
                base: "#DE1C17",
                lighten5: "#FDF3F3",
            },
            "light-blue": {
                base: "#3CB4E5",
                lighten5: "#E2F4FB",
            },
            purple: {
                base: "#9D5EDC",
                lighten5: "#FBF9FE",
            },
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00a4e0",
            secondary: "#445163",
            accent: "#00a4e0",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

export const counselTheme = {
    themes: {
        light: {
            primary: "#1976D2",
            secondary: "#424242",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme|counselTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
