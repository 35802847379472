<template>
    <v-tab-item v-bind="{ value }" class="fill-height">

        <v-card color="grey darken-4" elevation="10" class="chat-area__card">
            <div class="pa-16px pa-md-24px">
                <v-row no-gutters class="white--text">
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="auto">
                                <v-btn text icon tile x-small color="white" class="mr-4px" @click="$emit('back')"> <v-icon large>mdi-chevron-left</v-icon></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <h4 class="tit tit--sm white--text">Helper</h4>
                            </v-col>
                        </v-row>                        
                    </v-col>
                    <v-col cols="auto">
                        <v-avatar color="#FFB82F" class="v-size--xx-large"><i class="icon icon-chatbot" /></v-avatar>
                    </v-col>
                </v-row>
            </div>
            <v-list class="chat-list--group">
                <template v-for="(message, index) in messages">
                    <v-list-item :key="`${message._id}--query`" class="chat-list chat-list--right" :class="index !== 0 ? 'mt-8px mt-md-16px' : ''">
                        <v-card color="#F2F6FF" class="chat-list__card">
                            <div class="pa-12px pa-md-16px">
                                <p class="txt txt--sm txt--dark">{{ message?.query }}</p>
                            </div>
                        </v-card>
                    </v-list-item>
                    <v-list-item :key="`${message._id}--reply`" class="chat-list chat-list--left" :class="index !== 0 ? 'mt-8px mt-md-16px' : ''">
                        <v-card color="grey lighten-5" class="chat-list__card">
                            <div class="pa-12px pa-md-16px">
                                <v-row class="row--xs flex-nowrap">
                                    <v-col cols="auto">
                                        <v-avatar color="#FFB82F" size="24"><i class="icon icon-chatbot icon-chatbot--sm" /></v-avatar>
                                    </v-col>
                                    <v-col class="chat-list__replay">
                                        <div v-if="!message?.reply" class="chat-list__loading">
                                            <v-progress-linear stream indeterminate buffer-value="0" />
                                        </div>
                                        <p v-else class="txt txt--sm txt--dark">{{ message?.reply }}</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-list-item>
                </template>
                <template v-if="!messages.length">
                    <v-list-item class="chat-list">
                        <p class="txt txt--sm txt--light">No messages, yet</p>
                    </v-list-item>
                </template>
            </v-list>
            <v-card-actions class="grey lighten-1">
                <v-text-field v-model="query" flat solo rounded hide-details placeholder="내용을 입력하세요." v-bind="{ loading }" @keydown.enter="send">
                    <template #append>
                        <v-btn text icon v-bind="{ loading, disabled: typing }" class="mr-n4 v-size--xx-large" @click="send">
                            <v-icon color="grey darken-4">mdi-send</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-actions>
        </v-card>

        <!-- <v-sheet color="primary" class="d-flex flex-column" style="min-height: 320px">
            <v-card-title class="white--text" style="flex: 0 0 auto">
                <v-btn text icon tile color="white" class="mr-2" @click="$emit('back')"> <v-icon large>mdi-chevron-left</v-icon> </v-btn>
                Helper
            </v-card-title>
            <v-list style="flex: 1 0 auto; overflow-y: auto" max-height="60vh">
                <template v-for="message in messages">
                    <v-list-item :key="`${message._id}--query`">
                        <v-list-item-content>
                            {{ message?.query }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :key="`${message._id}--reply`" class="grey lighten-5">
                        <v-list-item-avatar>
                            <v-icon>mdi-robot-excited</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-progress-linear v-if="!message?.reply" stream indeterminate buffer-value="0" />
                            <span v-else> {{ message?.reply }} </span>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-if="!messages.length">
                    <v-list-item>
                        <v-list-item-content> No messages, yet </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <v-card-actions style="flex: 0 0 auto">
                <v-text-field v-model="query" flat dense solo rounded hide-details v-bind="{ loading }" @keydown.enter="send">
                    <template #append>
                        <v-btn text icon v-bind="{ loading, disabled: typing }" class="mr-n4" @click="send">
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-actions>
        </v-sheet> -->
    </v-tab-item>
</template>

<script>
export default {
    props: {
        value: { type: String }, // tab
        tab: { type: String }, // current tab

        room: { type: Object, default: () => ({}) },
        typing: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: null,
    }),
    computed: {
        messages() {
            return this.room?.messages || [];
        },
    },
    methods: {
        send() {
            const { _id: _room } = this.room;
            const { query } = this;
            const message = { _room, query };
            this.$emit("send", message);
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-list{
    &--group.v-list{
        padding: 16px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 204px;
        min-height: 204px;
    }
    &.v-list-item{
        margin: -4px 0;
        padding: 0;
        min-height: 0;
        &.chat-list--left{
            justify-content: flex-start;
            padding-right: 20px;
        }
        &.chat-list--right{
            justify-content: flex-end;
            padding-left: 20px;
            text-align: right;
        }
    }
    &__card{
        margin: 4px 0;
        max-width: 100%;
    }
    &__replay{
        max-width: calc(100% - 32px)
    }   
    &__loading{
        min-width: 80px;
        margin-top: 10px;
    }
}
.v-card__actions{
    --input-height: 50px;
    padding: 12px;
    ::v-deep{
        .v-text-field--rounded>.v-input__control>.v-input__slot {
            padding-left: 16px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .chat-list{
        &--group.v-list{
            padding: 24px;
            max-height: 288px;
            min-height: 288px;
        }
        &.v-list-item{
            margin: -8px 0;
        }        
        &__card{
            margin: 8px 0;
            max-width: 100%;
        }
    }
    .v-card__actions{
        --input-height: 56px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>