<template>
    <div class="main-media">
        <v-card rounded>
            <v-responsive :aspect-ratio="924 / 520" class="w-100">
                <iframe src="https://www.youtube.com/embed/NE1XZHUTKzQ?si=TspTkKYqiZ1HwlIs" title="[덴티움 X 송강호] 덴티움엔 다 계획이 있구나, 22년 전부터라니(30)" width="100%" height="100%" class="w-100" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </v-responsive>
        </v-card>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.main-media{
    --rounded: 8px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .main-media{
        --rounded: 12px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>