import brands from "./brands";
import catalogues from "./catalogues";
import categories from "./categories";
import coupons from "./coupons";
import displays from "./displays";
import exhibitions from "./exhibitions";
import inquires from "./inquires";
import islands from "./islands";
import orders from "./orders";
import products from "./products";
import reviewImages from "./review-images";
import reviews from "./reviews";
import shippings from "./shippings";
import showrooms from "./showrooms";

export default {
    brands,
    catalogues,
    categories,
    coupons,
    displays,
    exhibitions,
    inquires,
    islands,
    orders,
    products,
    reviewImages,
    reviews,
    shippings,
    showrooms,
};
