export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_input__date = {
    ...attrs_input,
    type: "date",
    max: "2999-12-31",
};

export const attrs_input__verticalTable = {
    flat: true,
    solo: true,
    class: "rounded-0",
    hideDetails: true,
    "persistent-placeholder": true,
};

export const attrs_input_secondary = {
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_input_tertiary = {
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_switch = {
    inset: true,
    hideDetails: "auto",
    class: "pa-0 ma-0 ml-1 mr-n3",
};

// Client
// Button
export const btn_primary = {
    color: "primary",
};
export const btn_primary2 = {
    color: "primary",
};
export const btn_primary3 = {
    color: "grey",
};
export const btn_secondary = {
    color: "grey lighten-3",
    outlined: true,
};
export const btn_secondary2 = {
    color: "white",
    outlined: true,
};
export const btn_tertiary = {
    color: "primary",
    outlined: true,
};

// Checkbox
export const checkbox_primary = {
    color: "grey darken-4",
    "hide-details": "auto",
};
export const checkbox_secondary = {
    color: "primary",
    "hide-details": "auto",
};

// Radio
export const radio_primary = {
    color: "grey darken-4",
};
export const radio_secondary = {
    color: "primary",
};
