<template>
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-global">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22949 10C1.22949 5.02944 5.25893 1 10.2295 1C15.2001 1 19.2295 5.02944 19.2295 10C19.2295 14.9706 15.2001 19 10.2295 19C5.25893 19 1.22949 14.9706 1.22949 10ZM10.2295 2.25581C5.95249 2.25581 2.48531 5.723 2.48531 10C2.48531 14.277 5.95249 17.7442 10.2295 17.7442C14.5065 17.7442 17.9737 14.277 17.9737 10C17.9737 5.723 14.5065 2.25581 10.2295 2.25581Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22949 9.37201H19.2295V10.6278H1.22949V9.37201Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4697 1.85439C8.94432 1.35252 9.53876 1 10.2294 1C10.9201 1 11.5145 1.35252 11.9892 1.85439C12.4625 2.35493 12.8599 3.04553 13.1804 3.84684C13.8229 5.45293 14.2062 7.62868 14.2062 10C14.2062 12.3713 13.8229 14.5471 13.1804 16.1532C12.8599 16.9545 12.4625 17.6451 11.9892 18.1456C11.5145 18.6475 10.9201 19 10.2294 19C9.53876 19 8.94432 18.6475 8.4697 18.1456C7.99633 17.6451 7.59897 16.9545 7.27844 16.1532C6.63601 14.5471 6.25269 12.3713 6.25269 10C6.25269 7.62868 6.63601 5.45293 7.27844 3.84684C7.59897 3.04553 7.99633 2.35493 8.4697 1.85439ZM8.44444 4.31323C7.87484 5.73724 7.5085 7.74754 7.5085 10C7.5085 12.2525 7.87484 14.2628 8.44444 15.6868C8.72994 16.4005 9.05418 16.936 9.38212 17.2827C9.70879 17.6282 9.99533 17.7442 10.2294 17.7442C10.4635 17.7442 10.7501 17.6282 11.0767 17.2827C11.4047 16.936 11.7289 16.4005 12.0144 15.6868C12.584 14.2628 12.9504 12.2525 12.9504 10C12.9504 7.74754 12.584 5.73724 12.0144 4.31323C11.7289 3.59949 11.4047 3.06403 11.0767 2.71727C10.7501 2.37184 10.4635 2.25581 10.2294 2.25581C9.99533 2.25581 9.70879 2.37184 9.38212 2.71727C9.05418 3.06403 8.72994 3.59949 8.44444 4.31323Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30987 3.31055L4.75387 3.75454C6.15598 5.15666 8.09117 6.02278 10.2298 6.02278C12.3685 6.02278 14.3037 5.15666 15.7058 3.75454L16.1498 3.31055L17.0378 4.19854L16.5938 4.64254C14.9658 6.27053 12.7149 7.27859 10.2298 7.27859C7.74472 7.27859 5.49386 6.27053 3.86587 4.64254L3.42188 4.19854L4.30987 3.31055Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86587 15.3568C5.49386 13.7288 7.74472 12.7207 10.2298 12.7207C12.7149 12.7207 14.9658 13.7288 16.5938 15.3568L17.0378 15.8008L16.1498 16.6887L15.7058 16.2448C14.3037 14.8426 12.3685 13.9765 10.2298 13.9765C8.09117 13.9765 6.15598 14.8426 4.75387 16.2448L4.30987 16.6887L3.42188 15.8008L3.86587 15.3568Z"/>
    </svg>


</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-global {
    width: 20px;
}
</style>
