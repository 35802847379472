<template>
    <v-menu v-model="shows" :close-on-content-click="false" content-class="chat-area elevation-0 transparent">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-sheet v-bind="{ loading }" class="chat-area__inner">
            <v-tabs-items v-model="tab" class="fill-height transparent">
                <tab-room-list value="list" v-bind="{ tab, rooms }" v-on="{ join, back, close }" />
                <tab-room-view value="view" v-bind="{ tab, room, typing, loading }" v-on="{ send, back }" />
            </v-tabs-items>
        </v-sheet>
    </v-menu>
</template>

<script>
import { io } from "socket.io-client";

import TabRoomList from "./tab-room-list.vue";
import TabRoomView from "./tab-room-view.vue";

export default {
    components: {
        TabRoomList,
        TabRoomView,
    },
    data: () => ({
        socket: null,

        _room: null,
        rooms: [],

        tab: "list",
        shows: false,
        typing: false,
        loading: false,
    }),
    computed: {
        room() {
            return this.rooms.find(({ _id }) => _id == this.$data._room) || null;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            if (this.shows) {
                if (!this.socket) {
                    if (this.loading) return;
                    else this.loading = true;

                    const { accessToken } = this.$store.state;
                    const sessionId = localStorage.getItem("sessionId");
                    const options = {
                        // path: `/socket`,
                        // autoConnect: false,
                        transports: ["websocket"],
                        auth: { accessToken, sessionId },
                    };
                    if (!accessToken) delete options.auth.accessToken;
                    if (!sessionId) delete options.auth.sessionId;
                    this.socket = io("/ai-prompt-chat", options);
                    this.socket.on("session", ({ id: sessionId }) => {
                        this.socket.auth = { sessionId };
                        localStorage.setItem("sessionId", sessionId);
                    });
                    this.socket.on("rooms", ({ rooms }) => {
                        this.rooms = rooms;
                        this.loading = false;
                    });

                    this.socket.on("joinRoom", ({ room }) => {
                        this.updateRoomItem(room);
                        this.$data._room = room?._id;
                        this.tab = "view";
                        this.loading = false;
                    });

                    this.socket.on("sendMessageQuery", ({ message }) => {
                        console.log("sendMessageQuery", { message });
                        const room = this.rooms.find(({ _id }) => _id == message?._room);
                        if (room?.messages) room.messages.push(message);
                        this.typing = true;
                    });
                    this.socket.on("sendMessageReply", ({ message }) => {
                        console.log("sendMessageReply", { message });
                        const room = this.rooms.find(({ _id }) => _id == message?._room);
                        const index = room?.messages?.findIndex?.(({ _id }) => _id == message?._id);
                        if (index >= 0) room.messages.splice(index, 1, message);
                        this.typing = false;
                        this.loading = false;
                    });
                }
            }
        },
        join({ _id: _room } = {}) {
            this.loading = true;
            this.socket.emit("joinRoom", _room);
        },
        send({ _room, query }) {
            this.loading = true;
            this.socket.emit("sendMessage", { _room, query });
        },

        updateRoomItem(room) {
            const index = this.rooms.findIndex(({ _id }) => _id == room?._id);
            if (index >= 0) {
                this.rooms.splice(index, 1, room);
            } else this.rooms = [room, ...this.rooms];
        },
        back() {
            this.tab = "list";
        },
        close() {
            this.shows = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.chat-area {
    min-width: calc(100vw - 14px) !important;
    max-width: calc(100vw - 14px);
    padding: 0 66px 0 0;
    &__inner {
        width: 100%;
        height: 90vh;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: transparent !important;
    }
    .v-tabs-items,
    .v-window-item {
        width: 100%;
        height: 100%;
    }
    .v-window-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 18px 0 0 0;
    }
    ::v-deep {
        .chat-area__card {
            --card-border-radius: 12px;
            width: 100%;
            overflow: hidden;
            background-color: transparent !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .chat-area {
        min-width: 476px !important;
        max-width: 476px;
        padding: 0 76px 56px 0;
        ::v-deep {
            .chat-area__card {
                --card-border-radius: 16px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
