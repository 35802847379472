<template>
    <v-btn v-scroll="onScroll" @click="$vuetify.goTo(target, options)" elevation="4" icon fixed bottom right color="white" transition="slide-x-transition" class="v-size--xx-large scroll-top">
        <icon-scroll-top class="grey--text text--darken-1" />
    </v-btn>
</template>

<script>
import IconScrollTop from "@/components/client/icons/icon-scroll-top.vue";
export default {
    components: {
        IconScrollTop,
    },
    data(){
        return {
            type: 'number',
            number: 0,
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',

            show: false,
        };
    },
    mounted(){
    },
    methods: {
        onScroll : function() {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var scrollTopBtn = document.querySelector('.scroll-top');
            if(scrollTopBtn !== null){
                if(scrollTop > window.innerHeight/2){
                    scrollTopBtn.classList.add('active');
                }else {
                    scrollTopBtn.classList.remove('active');
                }
            }
        },
    },
    computed: {
        target : function() {
            const value = this[this.type]
            if (!isNaN(value)) return Number(value)
            else return value
        },
        options : function() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.scroll-top{
    z-index: 10;
}
.scroll-top.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #fff !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>