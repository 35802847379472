export const initForm = (form = {}) => ({
    ...form,

    code: form.code || null,
    category: form.category || null,

    writer: {
        ...(form.writer || {}),
        name: form.writer?.name || null,
        phone: form.writer?.phone || null,
    },

    meta: {
        ...(form.meta || {}),
        _terms: form.meta?._terms || [],
    },
});
