<template>
    <header v-scroll="fixedScroll" class="header">
        <!-- S: PC -->
        <div class="header__inner">
            <div class="header-body">
                <v-container class="container--lg">
                    <div class="header-contents">
                        <v-row align="center" class="row--lg">
                            <v-col cols="auto">
                                <div class="logo">
                                    <h1>
                                        <router-link to="/" title="홈으로 이동"><span class="d-sr-only">브라이트 임플란트</span></router-link>
                                    </h1>
                                </div>
                            </v-col>
                            <v-col>
                                <v-row class="row--sm">
                                    <v-col cols="auto">
                                        <client-gnb className="gnb" />
                                    </v-col>
                                    <!-- <v-col v-if="accessToken" cols="auto">
                                        <v-btn to="/shop" rounded small color="grey lighten-5" class="font-weight-bold">MALL</v-btn>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                            <v-col cols="auto">
                                <v-row class="row--xs">
                                    <v-col cols="auto">
                                        <template v-if="!accessToken">
                                            <v-btn to="/login" icon text color="grey darken-4" class="header-quick__btn">
                                                <icon-person />
                                            </v-btn>
                                            <p class="header-quick__text">로그인</p>
                                        </template>
                                        <template v-else>
                                            <v-btn icon text color="grey darken-4" @click="logout" class="header-quick__btn">
                                                <icon-logout />
                                            </v-btn>                                        
                                            <p class="header-quick__text">로그아웃</p>
                                        </template>
                                    </v-col>
                                    <v-col v-if="accessToken" cols="auto">
                                        <v-btn to="/mypage/orders" icon text color="grey darken-4" class="header-quick__btn">
                                            <icon-person />
                                        </v-btn>
                                        <p class="header-quick__text">마이페이지</p>
                                    </v-col>
                                    <v-col v-if="accessToken" cols="auto">
                                        <main-cart-button to="/mypage/cart" class="header-quick__btn">
                                            <template v-slot:default="{ carts }">
                                                <!-- <em class="badge">{{ carts.length }}</em> -->
                                                <span>
                                                    <v-badge :value="carts.length != 0" dot bordered overlap color="red">
                                                        <icon-shopping-bag />
                                                    </v-badge>
                                                </span>
                                            </template>
                                        </main-cart-button>
                                        <p class="header-quick__text">장바구니</p>
                                    </v-col>
                                    <v-col v-if="accessToken" cols="auto">
                                        <v-btn to="/mypage/likes" icon text color="grey darken-4" class="header-quick__btn">
                                            <icon-heart />
                                        </v-btn>                                        
                                        <p class="header-quick__text">찜하기</p>
                                    </v-col>
                                    <v-col v-if="accessToken" cols="auto">
                                        <v-btn to="/mypage/questions" icon text color="grey darken-4" class="header-quick__btn">
                                            <icon-chat />
                                        </v-btn>
                                        <p class="header-quick__text">문의하기</p>
                                    </v-col>
                                    <!-- <v-col cols="auto">
                                        <v-btn icon text color="grey darken-4">
                                            <icon-search />
                                        </v-btn>
                                    </v-col> -->
                                    <!-- <v-col cols="auto">
                                        <v-btn icon text color="grey darken-4">
                                            <icon-global />
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- E: PC -->

        <!-- S: Mobile -->
        <!-- mobile-header -->
        <div class="mobile-header">
            <div class="logo">
                <h1>
                    <router-link to="/" title="홈으로 이동"><span class="d-sr-only">브라이트 임플란트</span></router-link>
                </h1>
            </div>
            <!-- <button class="mobile-header__search"><icon-search /><span class="d-sr-only">제품검색창열기</span></button> -->
        </div>
        <!-- mobile-searchbar -->
        <!-- <div class="mobile-searchbar">
            <div class="mobile-searchbar__head">
                <button class="mobile-searchbar__close" type="button"><span class="d-sr-only">모바일 검색창 닫기</span></button>
                <div class="mobile-searchbar__form">
                    <product-search />
                </div>
            </div>
        </div> -->
        <!-- mobile-hamburger -->
        <div class="mobile-hamburger">
            <button type="button" aria-pressed="false" class="mobile-hamburger__btn">
                <span class="mobile-hamburger__line"></span>
            </button>
        </div>
        <!-- mobile-sidebar -->
        <div class="mobile-sidebar">
            <div class="mobile-sidebar-head">
                <button class="mobile-sidebar__close" type="button"><span class="d-sr-only">모바일 메뉴바 닫기</span></button>
                <div class="mobile-sidebar__info">
                    <router-link v-if="!accessToken" class="mobile-sidebar__member" to="/login">로그인 해주세요</router-link>
                    <a href="#" v-else @click="logout" class="mobile-sidebar__member">로그아웃</a>
                </div>
            </div>

            <div class="mobile-sidebar-body">
                <ul v-if="accessToken" class="mobile-sidebar__mylink">
                    <li>
                        <router-link class="link" to="/mypage/orders">
                            <icon-person />
                            <span>마이페이지</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/mypage/likes">
                            <icon-heart />
                            찜하기
                        </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/mypage/cart">
                            <icon-shopping-bag />
                            장바구니
                        </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/mypage/questions">
                            <icon-chat />
                            문의하기
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link class="link" to="/">
                            <icon-global />
                            언어설정
                        </router-link>
                    </li> -->
                </ul>

                <client-gnb className="gnb-mo" />

                <!-- <ul v-if="accessToken" class="mobile-sidebar__link">
                    <li>
                        <router-link class="link" to="/shop">MALL<v-icon>mdi-chevron-right</v-icon></router-link>
                    </li>
                </ul> -->
            </div>
        </div>
        <div class="mobile-header-dimmed"></div>
        <!-- E: Mobile -->
    </header>
</template>

<script>
import { EXHIBITION_GROUPS } from "@/assets/variables";

import IconPerson from "@/components/client/icons/icon-person.vue";
import IconLogout from "@/components/client/icons/icon-logout.vue";
import IconShoppingBag from "@/components/client/icons/icon-shopping-bag.vue";
import IconHeart from "@/components/client/icons/icon-heart.vue";
import IconChat from "@/components/client/icons/icon-chat.vue";
// import IconSearch from "@/components/client/icons/icon-search.vue";
import IconGlobal from "@/components/client/icons/icon-global.vue";

import MainCartButton from "../main/main-cart-button.vue";
// import MainHeaderNotice from "./main-header-notice.vue";
// import MainShopCategories from "../main/main-shop-categories.vue";

// import ProductSearch from "@/components/client/shop/products/product-search.vue";

import ClientGnb from "@/components/client/inc/client-gnb.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        IconPerson,
        IconLogout,
        IconShoppingBag,
        IconHeart,
        IconChat,
        // IconSearch,
        IconGlobal,

        MainCartButton,
        // MainHeaderNotice,
        // MainShopCategories,

        // ProductSearch,

        ClientGnb,
    },
    data: () => ({
        // searchKey: "name",
        // searchValue: null,

        groups,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // const { searchKey, searchValue } = this.$route?.query || {};
            // this.searchKey = searchKey || "name";
            // this.searchValue = searchValue;

            this.$nextTick(function () {
                this.header();
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        // search() {
        //     this.$router.push(`/shop/search?searchKey=${this.searchKey}&searchValue=${this.searchValue}`);
        // },

        // header

        fixedScroll: function () {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (scrollTop > 2) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
        header: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    hamburgerCreate();
                }
            }

            // Mobile Header
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                // searchBtn = document.querySelector(".mobile-header__search"),
                closeSidebar = document.querySelector(".mobile-sidebar__close"),
                // closeSearchbar = document.querySelector(".mobile-searchbar__close"),
                dimmed = document.querySelector(".mobile-header-dimmed"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link[data-toggle]");

            hamburgerBtn.addEventListener("click", hamburgerDestroy);
            // searchBtn.addEventListener("click", searchDestroy);
            closeSidebar.addEventListener("click", hamburgerCreate);
            // closeSearchbar.addEventListener("click", searchCreate);
            dimmed.addEventListener("click", hamburgerCreate);
            // dimmed.addEventListener("click", searchCreate);

            // Mobile Hamburger
            function hamburgerCreate() {
                let attr = header.getAttribute("data-dimmed");
                if (attr !== null) {
                    hamburgerBtn.setAttribute("aria-pressed", "false");
                    header.setAttribute("data-sidebar", "closed");
                    header.setAttribute("data-dimmed", "closed");
                    mobileCategory.forEach((element) => {
                        element.setAttribute("data-toggle", "closed");
                    });
                }
            }
            function hamburgerDestroy() {
                hamburgerBtn.setAttribute("aria-pressed", "true");
                header.setAttribute("data-sidebar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
            // // Mobile Search
            // function searchCreate() {
            //     // HTML.removeAttribute("data-scroll");
            //     header.setAttribute("data-searchbar", "closed");
            //     header.setAttribute("data-dimmed", "closed");
            // }
            // function searchDestroy() {
            //     // HTML.setAttribute("data-scroll", "false");
            //     header.setAttribute("data-searchbar", "opened");
            //     header.setAttribute("data-dimmed", "opened");
            // }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    // watch: {
    //     "$route.query.searchKey"() {
    //         this.init();
    //     },
    //     "$route.query.searchValue"() {
    //         this.init();
    //     },
    // },
};
</script>

<style lang="scss" scoped>
.header-quick{
    &__text{
        font-size: 12px;
        line-height: 1;
        color: var(--v-grey-lighten1);
        text-align: center;
        margin-top: 4px;
    }    
    &__btn{
        &::before{
            display: none;
        }        
    }
}
::v-deep{
    .v-badge--dot .v-badge__badge{
        transform: translate(2px, -2px);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-application .v-btn.grey--text.text--darken-4:hover {
        background-color: var(--v-primary-base) !important;
        color: #fff !important;
    }
}
@media (min-width:1200px){
}
</style>
