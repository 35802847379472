var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', _vm._b({
    staticClass: "fill-height"
  }, 'v-tab-item', {
    value: _vm.value
  }, false), [_c('v-card', {
    staticClass: "chat-area__card",
    attrs: {
      "color": "grey darken-4",
      "elevation": "10"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-24px",
    style: _vm.$vuetify.breakpoint.mdAndUp ? 'height: 112px;' : ''
  }, [_c('v-row', {
    staticClass: "white--text",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-4px",
    attrs: {
      "text": "",
      "icon": "",
      "tile": "",
      "x-small": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-chevron-left")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text mb-4px"
  }, [_vm._v("Chat with")]), _c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("AI Helper")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    staticClass: "v-size--xx-large",
    attrs: {
      "color": "#FFB82F"
    }
  }, [_c('i', {
    staticClass: "icon icon-chatbot"
  })])], 1)], 1)], 1), [_c('v-list', {
    staticClass: "chat-list--group"
  }, [_vm._l(_vm.items, function (room) {
    var _room$message__last, _room$message__last2;
    return [_c('v-list-item', {
      key: room._id,
      staticClass: "chat-list",
      on: {
        "click": function ($event) {
          return _vm.$emit('join', room);
        }
      }
    }, [_c('div', {
      staticClass: "txt txt--sm"
    }, [_c('p', {
      staticClass: "txt--dark text-truncate"
    }, [_vm._v(_vm._s((room === null || room === void 0 ? void 0 : (_room$message__last = room.message__last) === null || _room$message__last === void 0 ? void 0 : _room$message__last.query) || "No messages"))]), _c('p', {
      staticClass: "txt--light mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(((room === null || room === void 0 ? void 0 : (_room$message__last2 = room.message__last) === null || _room$message__last2 === void 0 ? void 0 : _room$message__last2.createdAt) || (room === null || room === void 0 ? void 0 : room.createdAt)).toDateTime()))])])])];
  })], 2)], _c('v-btn', {
    staticClass: "chat-button",
    attrs: {
      "color": "grey lighten-1",
      "dark": "",
      "tile": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('join');
      }
    }
  }, [_vm._v(" Start New Chat "), _c('v-spacer'), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-send")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }