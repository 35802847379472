export const CHAT_MESSAGE_TYPES = {
    ADMIN_END: { value: "ADMIN_END", text: "관리자 메시지" },
    AI_PROMPT: { value: "AI_PROMPT", text: "프롬프트 질의" },
    AUTO_NOTE: { value: "AUTO_NOTE", text: "시스템 메시지" },
    USER_PEER: { value: "USER_PEER", text: "사용자 메시지" },
};

export const AUTO_NOTE_CHAT_MESSAGE_CODES = {
    SYSTEM_GREETING: { value: "SYSTEM_GREETING", text: "환영인사", placeholder: "브라이트 임플란트에 오신 걸 환영합니다." },
    OUT_OF_BUSINESS: { value: "OUT_OF_BUSINESS", text: "상담시간", placeholder: "지금은 상담시간이 아닙니다." },
    PLEASE_STAND_BY: { value: "PLEASE_STAND_BY", text: "대기요청", placeholder: "상담사를 연결하고 있습니다." },
    MANAGER_CONNECT: { value: "MANAGER_CONNECT", text: "연결안내", placeholder: "상담사가 연결되었습니다." },
    TERMS_AGREEMENT: { value: "TERMS_AGREEMENT", text: "약관동의", placeholder: "서비스 이용을 위해 이용약관 동의가 필요합니다.\r\n동의하시지 않는 경우 서비스를 이용하실 수 없습니다." },
    SYSTEM_FAREWELL: { value: "SYSTEM_FAREWELL", text: "작별인사", placeholder: "서비스를 사용해주셔서 감사합니다." },
};
