<template>
    <v-card rounded flat class="inquiary-card">
        <div class="pa-20px pa-md-30px pa-lg-80px">
            <v-form v-model="isValid">
                <vertical-form-table v-model="form" v-bind="{ items }" hideTitle @input="emit">
                    <template #문의유형>
                        <v-btn-toggle v-model="form.category" color="grey lighten-4" class="v-btn-toggle--primary" @change="emit">
                            <v-btn rounded outlined x-small value="제품상담">제품상담</v-btn>
                            <v-btn rounded outlined x-small value="회원가입문의">회원가입문의</v-btn>
                            <v-btn rounded outlined x-small value="배송/기타 문의">배송/기타 문의</v-btn>
                        </v-btn-toggle>
                    </template>
                    <template #foot>
                        <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
                        <div class="mt-20px mt-md-40px">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ ...btn_primary, loading, disabled }" class="v-size--xx-large w-100 min-w-sm-240px" @click="save">신청하기</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </vertical-form-table>
            </v-form>
        </div>
    </v-card>
</template>

<script>
import { attrs_input_tertiary, btn_primary, initForm, rules } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

const items = [
    {
        key: "category",
        term: "문의유형",
        required: true,
        ...attrs_input_tertiary,
    },
    {
        key: "meta.name",
        term: "이름",
        type: "text",
        rules: rules.name,
        required: true,
        label: "이름",
        ...attrs_input_tertiary,
    },
    {
        key: "meta.phone",
        term: "연락처",
        type: "phone",
        rules: rules.phone,
        required: true,
        label: "연락처",
        ...attrs_input_tertiary,
    },
    {
        key: "content",
        term: "문의사항",
        type: "text",
        rules: rules.content,
        required: true,
        label: "문의사항",
        ...attrs_input_tertiary,
    },
];

export default {
    components: {
        VerticalFormTable,
        TermsOfAgreementsShort,
    },
    props: {
        value: { type: Object, default: initForm },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initForm(),

        items,
        btn_primary,
        isValid: false,
        isTermsValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        emit() {
            this.form = initForm(this.form);
            this.$emit("input", this.form);
        },
        save() {
            if (!this.form.category) {
                alert("상담유형을 선택해주세요");
                return;
            }
            this.$emit("save");
        },
    },
};
</script>

<style lang="scss" scoped>
.inquiary-card {
    --rounded: 8px;
    --input-height: 50px;
    ::v-deep {
        .v-text-field {
            padding: 0;
            margin: 0;
        }
        .v-text-field__slot {
            min-height: var(--input-height) !important;
            display: flex;
            align-items: flex-end;
            .v-label {
                top: 0;
                transform: translateY(0) scale(1);
                font-size: 12px;
            }
        }
        .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
            border-color: var(--v-grey-darken4);
        }
    }
}
.v-application--is-ltr .v-btn-toggle>.v-btn.v-btn:not(:first-child), .v-application--is-rtl .v-btn-toggle>.v-btn.v-btn:not(:last-child) {
    border-left-width: 1px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .inquiary-card {
        --rounded: 12px;
        --input-height: 56px;
        ::v-deep {
            .v-text-field__slot {
                .v-label {
                    font-size: 14px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
