import axios from "@/plugins/axios";

let url = "/api/console/legacy/orders";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    patchState(data) {
        return axios.patch(`${url}/${data._id}/state/${data.state || ""}`, data).then((result) => result.data);
    },
};
