import Vue from "vue";
import Vuex from "vuex";

import api from "@/api";
import axios from "@/plugins/axios";
import dayjs from "@/plugins/vue-dayjs";

import { AUTO_NOTE_CHAT_MESSAGE_CODES, DISPLAY_CODES, SHIPPING_CODES, SHIPPING_TARGET_TYPES } from "@/assets/variables/constants";
import { initSetting, initSettingShopDisplayCode, initSettingShopShippingCode, initSettingSiteHelpChatMessages } from "@/assets/variables/inits";

import { bandBanners } from "./bandBanners";
import { counsel } from "./counsel.store";
import { siteHelpChat } from "./site-help-chat.store";

Vue.use(Vuex);

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

var carts = JSON.parse(sessionStorage.getItem("carts") || "[]");
var pickup = JSON.parse(sessionStorage.getItem("pickup") || "[]");
var watches = JSON.parse(sessionStorage.getItem("watches") || "[]");

var agreements = JSON.parse(sessionStorage.getItem("agreements") || "[]");

export default new Vuex.Store({
    state: {
        // user
        refreshToken,
        accessToken,
        payload,
        scope,
        user: undefined,

        // common
        setting: initSetting(),
        likes: undefined,

        // shop
        carts,
        pickup,
        watches,
        islands: [],
        categories: [],
        shipping__everyAsset: undefined,

        // join
        agreements,

        // ui
        main: { alert: true },
    },
    mutations: {
        // user
        agreements(state, agreements) {
            state.agreements = agreements;
            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];
            state.likes = [];

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.user = undefined;
            state.likes = undefined;
            state.carts = [];
            state.pickup = [];
            state.watches = [];
            state.agreements = [];

            axios.defaults.headers.common["Authorization"] = "";

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("carts");
            sessionStorage.removeItem("pickup");
            sessionStorage.removeItem("watches");
            sessionStorage.removeItem("agreements");
        },
        setUser(state, { user }) {
            state.user = user;
        },

        // common
        setSetting(state, { setting }) {
            state.setting = initSetting(setting);
        },
        setLikes(state, { likes }) {
            state.likes = likes;
        },

        // shop
        setCarts(state, { carts }) {
            state.carts = carts;
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        addToCart(state, carts) {
            state.carts = [].concat(state.carts, carts);
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        pickup(state, _carts) {
            state.pickup = _carts;
            sessionStorage.setItem("pickup", JSON.stringify(_carts));
        },
        setWatches(state, { watches }) {
            state.watches = watches;
            sessionStorage.setItem("watches", JSON.stringify(state.watches));
        },
        addToWatch(state, watches) {
            for (const watch of watches) {
                const index = state.watches.findIndex(({ _id }) => _id == watch._id);
                if (-1 < index) state.watches[index] = watch;
                else state.watches = [watch, ...state.watches];
                [].sort;
                state.watches = state.watches.sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 0));
            }
            sessionStorage.setItem("watches", JSON.stringify(state.watches));
        },
        setIslands(state, { islands }) {
            state.islands = islands;
        },
        setCategories(state, { categories }) {
            state.categories = categories;
        },
        setShipping__everyAsset(state, { shipping }) {
            state.shipping__everyAsset = shipping;
        },

        // ui
        hideMainAlert(state) {
            state.main.alert = false;
        },
    },
    actions: {
        // user
        async login({ dispatch, commit, state }, { username, password }) {
            const { refreshToken } = await api.auth.getRefreshToken({ username, password });
            const { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });

            await Promise.all(state.carts.map(async (cart) => await api.v1.me.carts.put(cart)));
            await Promise.all(state.watches.map(async (cart) => await api.v1.me.watches.put(cart)));

            dispatch("getCarts");
            dispatch("getWatches");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
        logout({ commit }) {
            commit("logout");
        },
        async getUser({ commit }) {
            const { user } = await api.v1.me.get();
            if (user?.meta?.CustNo) {
                const { customer, customerInfo, customerInfo2 } = await api.v1.dentium.customer.get();

                user.name = customer?.FullName;

                user.tel = customer?.TelNo || customerInfo?.Tel2;
                user.email = customerInfo?.Email?.trim?.() || null;

                user.business = {
                    ...(user.business || {}),
                    no: customer?.BizNo?.trim?.() || null,
                    name: customer?.FullName?.trim?.() || null,
                    type: customer?.BizType?.trim?.() || null,
                    service: customer?.BizKind?.trim?.() || null,
                    ceoName: customer?.Owner?.trim?.() || null,
                    postcode: customerInfo?.ZipCode?.trim?.() || null,
                    address1: customerInfo?.KorAddr1?.trim?.() || null,
                    address2: customerInfo?.KorAddr2?.trim?.() || null,
                };

                user.money = -(customerInfo2?.NonReceiptAmt || 0);
            }
            commit("setUser", { user });
            return user;
        },
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },

        // common
        async getSetting__v1({ commit }) {
            const { setting } = await api.v1.setting.get();
            commit("setSetting", { setting });
        },
        async getSetting__console({ commit }) {
            const { setting } = await api.console.setting.get();
            commit("setSetting", { setting });
            return setting;
        },
        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },

        // shop
        addToCart({ commit }, carts) {
            commit("addToCart", carts);
        },
        pickup({ commit }, _carts) {
            commit("pickup", _carts);
        },
        addToWatch({ commit }, watches) {
            commit("addToWatch", watches);
        },
        async getCarts({ commit }) {
            const { carts } = await api.v1.me.carts.gets();
            commit("setCarts", { carts });
        },
        async getWatches({ commit }) {
            const { watches } = await api.v1.me.watches.gets();
            commit("setWatches", { watches });
        },
        async getIslands({ commit }) {
            const { islands } = await api.v1.shop.islands.gets();
            commit("setIslands", { islands });
        },
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            commit("setCategories", { categories });
        },
        async getShipping__everyAsset({ commit }) {
            const { shipping } = await api.v1.shop.shippings.gets({
                headers: { mode: "item" },
                params: { targetType: SHIPPING_TARGET_TYPES.EVERY_ASSET.value },
            });

            commit("setShipping__everyAsset", { shipping });
        },
    },
    getters: {
        displayCodes(state) {
            const items = state.setting.shop.displayCodes.map(initSettingShopDisplayCode);

            items.push(...[...Object.values(DISPLAY_CODES).filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopDisplayCode));

            return items;
        },

        shippingCodes(state) {
            const items = state.setting.shop.shippingCodes.map(initSettingShopShippingCode);

            items.push(...[...Object.values(SHIPPING_CODES).filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopShippingCode));

            return items;
        },

        academyCategories(state) {
            return (state?.setting?.academy?.categories || []).map((text) => ({ text, value: text?.trim?.()?.toLowerCase?.()?.replaceAll?.(/\s/g, "-") || null }));
        },

        siteHelpChatMessages(state) {
            const items = state.setting.siteHelpChat.messages.map(initSettingSiteHelpChatMessages);

            items.push(...[...Object.values(AUTO_NOTE_CHAT_MESSAGE_CODES).filter(({ value }) => !items.some((item) => item.code == value))].map(({ text, value, placeholder }) => initSettingSiteHelpChatMessages({ name: text, code: value, text: null, placeholder })));

            return items;
        },
    },
    modules: {
        bandBanners,
        counsel,
        siteHelpChat,
    },
});
