var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-chat",
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "m2.61,0C1.17,0,0,1.17,0,2.61v12.32c0,.3.17.57.44.69.1.05.21.07.32.07.18,0,.35-.06.49-.18l3.48-2.9h8.96c1.44,0,2.61-1.17,2.61-2.61V2.61c0-1.44-1.17-2.61-2.61-2.61H2.61Zm-1.08,2.61c0-.6.49-1.08,1.08-1.08h11.08c.6,0,1.08.49,1.08,1.08v7.39c0,.6-.49,1.08-1.08,1.08H4.46c-.18,0-.35.06-.49.18l-2.44,2.04V2.61Zm2.93,1.08c-.42,0-.76.34-.76.76s.34.76.76.76h7.39c.42,0,.76-.34.76-.76s-.34-.76-.76-.76h-7.39Zm0,3.45c-.42,0-.76.34-.76.76s.34.76.76.76h4.93c.42,0,.76-.34.76-.76s-.34-.76-.76-.76h-4.93Zm12.93-2.22c-.42,0-.76.34-.76.76s.34.76.76.76c.6,0,1.08.49,1.08,1.08v10.11l-1.84-1.47c-.13-.11-.3-.17-.48-.17H7.54c-.6,0-1.08-.49-1.08-1.08v-.62c0-.42-.34-.76-.76-.76s-.76.34-.76.76v.62c0,1.44,1.17,2.61,2.61,2.61h8.35l2.87,2.3c.14.11.31.17.48.17.11,0,.22-.02.33-.07h0c.26-.13.43-.4.43-.69V7.54c0-1.44-1.17-2.61-2.61-2.61Z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }