export const initCenterPrompt = (prompt = {}) => ({
    ...prompt,

    group: prompt.group || null,

    index: prompt.index || null,
    query: prompt.query || null,
    reply: prompt.reply || null,

    isEnabled: prompt.isEnabled || false,
});
