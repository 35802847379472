import axios from "@/plugins/axios";

import carts from "./carts";
import coupons from "./coupons";
import destinations from "./destinations";
import inquires from "./inquires";
import inquiries from "./inquiries";
import legacy from "./legacy";
import likes from "./likes";
import logs from "./logs";
import orders from "./orders";
import points from "./points";
import purchases from "./purchases";
import reviews from "./reviews";
import watches from "./watches";

let url = "/api/v1/me";

export default {
    get() {
        return axios.get(`${url}/info`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/info`, data).then((result) => result.data);
    },
    verify(data) {
        return axios.post(`${url}/verify`, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },

    carts,
    coupons,
    destinations,
    inquires,
    inquiries,
    legacy,
    likes,
    logs,
    orders,
    points,
    purchases,
    reviews,
    watches,
};
