<template>
    <div class="main-visual">
        <swiper class="swiper" v-bind="{ options }">
            <template v-for="detail in slide">
                <swiper-slide :key="detail">
                    <v-responsive :aspect-ratio="1920 / 980" class="main-visual__inner" :style="'background-image: url('+detail+')'"></v-responsive>
                </swiper-slide>
            </template>
            <div class="swiper-control" slot="pagination">
                <div class="swiper-pagination"></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const options = {
    effect: "fade",
    allowTouchMove: true,
    centeredSlides: true,
    spaceBetween: 0,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    speed: 500,
    loop: true,
    pagination: {
        el: '.main-visual .swiper-pagination',
        clickable: true,
        renderBullet: function(index, className) {
            const number = (index + 1 < 10) ? '0' + (index + 1) : (index + 1);
            return '<span class="' + className + '">' + number + '</span>';
        },
    },
};

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        options,           
        slide : [
            "/images/main/visual1.gif",
            "/images/main/visual2.gif",
        ], 
    }),
};
</script>

<style lang="scss" scoped>
.main-visual{
    &__inner{
        width: 100%;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .swiper-control{
        top: unset;
        height: 20px;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        max-width: calc(var(--container-lg) + var(--container-gutter) * 2);
        padding: 0 var(--container-gutter);
        .swiper-pagination.swiper-pagination-bullets{
            position: relative;
            left: 0;
            bottom: 0;
            align-items: center;
        }
    }
}
::v-deep{
    .swiper-pagination{
        display: flex;
        justify-content: center !important;
    }
    .swiper-pagination-bullet{
        position: relative;
        display: flex;
        width: 16px;
        height: auto;
        background-color: transparent !important;
        font-size: 12px;
        font-weight: 700;
        text-align: left;
        color: rgba(17, 17, 17, .4);
        border: 0;
        margin: 0 12px 0 0;
        padding-right: 0;
        transition: 0.25s ease-out;
        opacity: 1;
        &:last-child{
            margin-right: 0;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 1px;
            background-color: var(--v-primary-base);
            position: absolute;
            border-radius: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%) scaleX(0);
            transform-origin: right center;
            transition: 0.25s ease-out;
        }
        &-active{
            width: 64px;
            color: var(--v-primary-base);
            &::after{
                transform: translateY(-50%) scaleX(1);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual{        
        .swiper-control{
            bottom: 60px;
            .swiper-pagination.swiper-pagination-bullets{
                justify-content: flex-start;
            }
        }
    }
    ::v-deep{
        .swiper-pagination-bullet{
            width: 20px;
            font-size: 14px;
            margin: 0 24px 0 0;
            &::after{
                width: 40px;
            }
            &-active{
                width: 84px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-visual{
        &__inner{
            max-height: 100vh;
            min-height: 100vh;
        }
    }
}
@media (min-width: 1300px) {
}
@media (min-width: 1740px) {
}
</style>
