<template>
    <client-page class="app--main">
        <template #contentsImmersive>
            <main-visual />

            <main-section class="main-section--products main-section--xs main-section--first">
                <tit-wrap-dot title="Products" large />
                <btn-primary to="/product" class="mb-20px mb-md-60px mb-lg-100px" />
                <main-products />
            </main-section>

            <main-section class="main-section--media main-section--xs main-section--last">
                <template #mainContainerImmersive><div class="main-section--media__bg"></div></template>
                <v-row>
                    <v-col cols="12" md="5">
                        <div class="h-100 d-flex flex-column justify-space-between">
                            <tit-wrap-dot title="Our Media" large />
                            <btn-primary dark to="/academy" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <main-media />
                    </v-col>
                </v-row>
            </main-section>

            <main-section class="main-section--inquiary main-section--sm">
                <v-row>
                    <v-col cols="12" md="5">
                        <tit-wrap-dot title="Inquiry" large>
                            <template #txtAdd> 상담 예약을 남겨주시면 친절히 상담해 드리겠습니다. </template>
                        </tit-wrap-dot>
                    </v-col>
                    <v-col cols="12" md="7" class="mt-n16px mt-md-0">
                        <div class="main-section--inquiary__right">
                            <form-input code="inquiry-call" skin="main-inquiry" />
                        </div>
                    </v-col>
                </v-row>
            </main-section>

            <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup" />
        </template>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MainSection from "@/components/client/main/main-section.vue";
import TitWrapDot from "@/components/client/dumb/tit-wrap-dot.vue";
import MainVisual from "@/components/client/main/main-visual/main-visual.vue";
import MainProducts from "@/components/client/main/main-products.vue";
import MainMedia from "@/components/client/main/main-media.vue";
import MainInquiry from "@/components/client/form/skin/main-inquiry/input.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        MainSection,
        TitWrapDot,
        MainVisual,
        MainProducts,
        MainMedia,
        MainInquiry,
        PopupLayer,
        BtnPrimary,
        FormInput,
    },
    data: () => ({
        popups: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    &--products {
        background-image: url(/images/ci-light.svg);
        background-position: right top;
        background-size: 50% auto;
    }
    &--media {
        position: relative;
        &__bg {
            width: 100%;
            height: calc(100% - var(--main-section-padding-y-xs) - (var(--tit-wrap-margin-xxl) / 2) - var(--tit-font-size-xl));
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url(/images/main/media-bg.jpg);
            background-position: center;
            background-size: cover;
            z-index: -1;
        }
    }
    &--inquiary {
        background-color: var(--v-grey-lighten5);
        &__right {
            width: 100%;
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100vw;
                height: calc(100% + var(--main-section-padding-y-sm) + 20px);
                bottom: calc(var(--main-section-padding-y-sm) * -1);
                left: 50%;
                transform: translateX(-50%);
                background-color: var(--v-primary-base);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section {
        &--media {
            &__bg {
                height: calc(100% - var(--main-section-padding-y-xs) - var(--tit-wrap-margin-xxl) - var(--tit-font-size-xl));
            }
        }
        &--inquiary {
            background-image: url(/images/main/inquiary-bg.jpg);
            &__right {
                &::before {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-section {
        &--media {
            &__bg {
                height: calc(100% - var(--main-section-padding-y-xs) - var(--tit-wrap-margin-xxl) - 120px);
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>
