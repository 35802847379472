var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm._t("bandBanner"), _c('main-header', _vm._b({}, 'main-header', {
    disableScrollTop: _vm.disableScrollTop
  }, false)), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("subHead"), _vm._t("contentsImmersive"), this.$slots['default'] ? _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_c('v-container', [_vm._t("default")], 2)], 1) : _vm._e()], 2), _c('main-footer'), _c('scroll-top'), !_vm.showsAiPromptChat ? _c('v-btn', _vm._g(_vm._b({
    staticClass: "kakao-btn v-size--xx-large",
    attrs: {
      "href": "https://pf.kakao.com/_PQTbs/chat",
      "target": "_blank",
      "elevation": "4",
      "icon": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "FFB82F"
    }
  }, 'v-btn', _vm.attrs, false), _vm.on)) : _vm._e(), _vm.showsAiPromptChat ? _c('ai-prompt-chat', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "chat-btn v-size--xx-large",
          attrs: {
            "elevation": "4",
            "icon": "",
            "fixed": "",
            "bottom": "",
            "right": "",
            "color": "FFB82F"
          }
        }, 'v-btn', attrs, false), on), [_c('div', {
          staticClass: "chat-btn__inner",
          attrs: {
            "id": "chat-btn"
          }
        })])];
      }
    }], null, false, 3704464873)
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }