import { io } from "socket.io-client";
import dayjs from "@/plugins/vue-dayjs";
import { SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";

/** @type {(import("vuex").Module)} */
export const counsel = {
    namespaced: true,

    state: {
        route: null,
        socket: null,
        session: {
            id: localStorage.getItem("sessionId") || null,
            name: localStorage.getItem("sessionName") || null,
        },

        _room: null,
        rooms: [],
        summary: { totalCount: 0 },

        filter: {
            searchValue: null,
            searchStage: null,
        },

        loading: false,
    },
    mutations: {
        setRoute(state, { route }) {
            state.route = route;
        },
        setSocket(state, { socket }) {
            state.socket = socket;
        },

        updateRooms(state, { rooms }) {
            let items = [...state.rooms];

            if (0 < items.length) {
                for (const room of rooms) {
                    const index = items.findIndex(({ _id }) => _id == room?._id);
                    if (0 <= index) items.splice(index, 1, room);
                    else items = items.concat(room);
                }
            } else {
                items = rooms;
            }

            items.sort((a, b) => (dayjs(a?.updatedAt).isAfter(b?.updatedAt) ? -1 : 1));

            state.rooms = items;
        },

        updateRoomItem(state, { room }) {
            let rooms = [...state.rooms];

            const index = rooms.findIndex(({ _id }) => _id == room?._id);
            if (0 <= index) {
                if (!rooms[index].isClosed && room.isClosed) {
                    state.summary.totalCount += 1;
                }
                rooms.splice(index, 1, room);
            } else rooms = rooms.concat(room);

            rooms.sort((a, b) => (dayjs(a?.updatedAt).isAfter(b?.updatedAt) ? -1 : 1));

            state.rooms = rooms;
        },

        setRoom(state, { _room }) {
            state._room = _room;
        },
        setFilter(state, { filter }) {
            state.filter = filter;
        },
        setSummary(state, { summary }) {
            state.summary = summary;
        },
        setLoading(state, { loading }) {
            state.loading = loading;
        },
    },
    actions: {
        async init({ commit, dispatch, state, rootState }, route) {
            if (!!state.socket) return;
            if (state.loading) return;
            else commit("setLoading", { loading: true });

            commit("setRoute", { route });

            const { accessToken } = rootState;
            const sessionId = localStorage.getItem("sessionId");
            const sessionName = localStorage.getItem("sessionName");

            const options = {
                transports: ["websocket"],
                auth: { accessToken, sessionId, sessionName },
            };
            if (!accessToken) delete options.auth.accessToken;
            if (!sessionId) delete options.auth.sessionId;
            if (!sessionName) delete options.auth.sessionName;

            const socket = io(route, options);

            commit("setRoute", { route });
            commit("setSocket", { socket });

            socket.on("session", ({ id, name }) => {
                localStorage.setItem("sessionId", id);
                localStorage.setItem("sessionName", name);
                commit("setSession", { session: { id, name } });

                socket.auth = { sessionId: id, sessionName: name };
                commit("setSocket", { socket });
                commit("setLoading", { loading: false });

                socket.emit("getRooms", { params: { stage: { $in: [SITE_HELP_CHAT_ROOM_STAGES.AWAITING_MANAGER.value, SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_PROGRESS.value] } }, headers: { returns: ["rooms"] } });

                socket.emit("getRooms", { params: { stage: { $in: [SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_COMPLETE.value] } }, headers: { skip: 0, limit: 50 } });

                commit("setLoading", { loading: true });
            });

            socket.on("getRooms", ({ rooms, summary }) => {
                commit("updateRooms", { rooms });
                if (summary) commit("setSummary", { summary });

                commit("setLoading", { loading: false });
            });

            socket.on("joinRoom", ({ room }) => {
                commit("updateRoomItem", { room });
                commit("setRoom", { _room: room._id });
            });

            socket.on("exitRoom", ({ room }) => {
                commit("updateRoomItem", { room });
            });

            socket.on("updateRoomItem", ({ room }) => {
                commit("updateRoomItem", { room });
            });

            socket.on("sendMessage", ({ message }) => {
                dispatch("updateMessageItem", { message });
            });

            socket.on("sendRoomUserTyping", ({ _room, isUserTyping }) => {
                dispatch("updateRoomItem", { room: { _id: _room, isUserTyping } });
            });

            socket.on("sendRoomManagerTyping", ({ _room, isManagerTyping }) => {
                dispatch("updateRoomItem", { room: { _id: _room, isManagerTyping } });
            });
        },

        getRooms({ state }, { params, headers }) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;

            socket.emit("getRooms", { params, headers });
        },

        getRoom({ state }, { _room } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("getRoom", { _room });
        },

        joinRoom({ state }, { _room } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("joinRoom", { _room });
        },

        exitRoom({ state }, { _room } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("exitRoom", { _room });
        },

        updateRoomItem({ state, commit, dispatch }, { room }) {
            const item = state.rooms.find(({ _id }) => _id == room?._id);
            if (item) {
                commit("updateRoomItem", { room: { ...item, ...room, updatedAt: room.updatedAt || Date.now() } });
            } else {
                dispatch("getRoom", { _room: room?._id });
            }
        },

        updateMessageItem({ state, commit, dispatch }, { message }) {
            const room = state.rooms.find(({ _id }) => _id == message?._room);
            if (room) {
                let messages = [...(room.messages || [])];

                const index = messages.findIndex(({ _id, tempId }) => _id == message?._id || tempId == message?.tempId);
                if (0 <= index) messages.splice(index, 1, message);
                else messages.push(message);

                commit("updateRoomItem", { room: { ...room, messages, updatedAt: message.createdAt || Date.now() } });
            } else {
                dispatch("getRoom", { _room: message?._room });
            }
        },

        sendMessage({ state }, { _room, message } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("sendMessage", { _room, message });
        },

        sendRoomManagerTyping({ state }, { _room, isManagerTyping } = {}) {
            /** @type {(import("socket.io-client").Socket)} */
            const socket = state.socket;
            socket.emit("sendRoomManagerTyping", { _room, isManagerTyping });
        },
    },
    getters: {
        room(state) {
            return state.rooms.find(({ _id }) => _id == state._room) || null;
        },
        rooms(state) {
            let rooms = state.rooms;

            const searchValue = state.filter.searchValue?.trim?.() || null;
            if (searchValue) {
                rooms = rooms.filter((item) => {
                    const { user } = item.owner;

                    const nameMatches = user?.name?.includes(searchValue);
                    if (nameMatches) return true;

                    const usernameMatches = user?.username?.includes(searchValue);
                    if (usernameMatches) return true;

                    const messagesMatches = item.messages?.some?.(({ content }) => content?.includes?.(searchValue));
                    if (messagesMatches) return true;
                });
            }

            const searchStage = state.filter.searchStage;

            switch (searchStage) {
                case SITE_HELP_CHAT_ROOM_STAGES.AWAITING_MANAGER.value:
                case SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_PROGRESS.value:
                case SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_COMPLETE.value: {
                    rooms = rooms.filter(({ stage }) => stage == searchStage);
                    break;
                }
            }

            return rooms;
        },
        moreRoomProps(state) {
            const total = state.summary.totalCount;
            const count = state.rooms.filter(({ isClosed }) => isClosed).length;

            return {
                shows: 0 < total - count,
                request: {
                    headers: {
                        skip: count,
                        limit: 50,
                    },
                },
            };
        },
    },
};
