import { USER_TYPES } from "../constants";

export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    email: user?.email || null,
    phone: user?.phone || null,

    bizNumber: user?.bizNumber || null,

    promoterCode: user?.promoterCode || null,

    type: user?.type ?? USER_TYPES.PERSON.value,

    business: {
        ...(user?.business || {}),
        no: user?.business?.no || null,
        name: user?.business?.name || null,
        type: user?.business?.type || null,
        service: user?.business?.service || null,
        ceoName: user?.business?.ceoName || null,
        postcode: user?.business?.postcode || null,
        address1: user?.business?.address1 || null,
        address2: user?.business?.address2 || null,
    },

    meta: {
        ...(user?.meta || {}),
        CustNo: user?.meta?.CustNo || null,
        mbCode: user?.meta?.mbCode || null,
        custCode: user?.meta?.custCode || null,
        old_custCode: user?.meta?.old_custCode || null,
    },
});
