var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "products-items row--xl mx-xl-n90px"
  }, _vm._l(_vm.product, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "px-xl-90px",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('router-link', {
      staticClass: "products-item",
      attrs: {
        "to": item.link
      }
    }, [_c('v-card', {
      attrs: {
        "rounded": ""
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": item.image,
        "max-width": "710",
        "aspect-ratio": 710 / 472
      }
    })], 1), _c('div', {
      staticClass: "products-item__contents pl-20px pl-md-30px pl-lg-40px ml-12px ml-md-30px ml-lg-40px"
    }, [_c('p', {
      staticClass: "txt txt--sm primary--text font-secondary font-weight-bold"
    }, [_vm._v(_vm._s(item.titleAdd))]), _c('h3', {
      staticClass: "tit"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "txt txt--lg txt--dark mt-12px mt-md-24px"
    }, [_vm._v(" " + _vm._s(item.txt) + " ")])])], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }